import React from "react";
import styles from "./Blogposts.module.scss";
import BlogpostPreview from "../BlogpostPreview/BlogpostPreview";

const Blogposts = ({ blogpostData }) => {
  return (
    <div className={styles.blogpostsWrapper}>
      {blogpostData.map((data, index) => (
        <BlogpostPreview data={data} key={index} />
      ))}
    </div>
  );
};

export default Blogposts;
