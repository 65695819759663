import React, { useEffect, useState } from "react";
import styles from "./RecipesPage.module.scss";
import RecipePreview from "../../Components/RecipePreview/RecipePreview";

import { useLanguage } from "../../context/Language";
import Error from "../../Components/Error/Error";
import { getRecipe, getRecipepage, getRecipes } from "../../utils/api";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

const RecipesPage = () => {
  const [recipe, setRecipe] = useState(undefined);
  const [recipes, setRecipes] = useState([]);
  const [textData, setTextData] = useState({});
  const { currentLanguage } = useLanguage();
  const [error, setError] = useState(false);

  const recipeId = window.location.pathname.split("/")[2];

  useEffect(() => {
    //fetch static stuff
    const fetchData = async () => {
      try {
        const reciperes = await getRecipe(recipeId);
        const recipesres = await getRecipes(currentLanguage);
        const textres = await getRecipepage(currentLanguage);
        setRecipe(reciperes.data);
        setTextData(textres.data);
        for (let i = 0; i < recipesres.data.length; i++) {
          if (`${recipesres.data[i].id}` === recipeId) {
            recipesres.data.splice(i, 1);
          }
        }
        setRecipes(recipesres.data);
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage]);

  if (error) {
    return <Error />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.titleAndInfo}>
          <h2>{recipe?.title}</h2>
          <div className={styles.tags}>
            <p>
              <span>{textData.serves}</span>
              {recipe?.serves}
            </p>
            <p>
              <span>{textData.prep}</span>
              {recipe?.prepMin} min
            </p>
            <p>
              <span>{textData.cook}</span>
              {recipe?.cookMin} min
            </p>
            <p>
              <span>{textData.ready}</span>
              {recipe?.readyMin} min
            </p>
          </div>
        </div>
        <div className={styles.information}>
          <div className={styles.left}>
            <h3>{textData.notes}</h3>
            {recipe ? <BlocksRenderer content={recipe?.notes} /> : <></>}
            <h3 className={styles.ingredientText}>{textData.ingredients}</h3>
            {recipe ? (
              <BlocksRenderer content={recipe?.ingredients} />
            ) : (
              <></>
            )}
          </div>
          <div className={styles.divider} />
          <div className={styles.right}>
            <img src={recipe?.image.url} alt="" />
            <h3>{textData.directions}</h3>
            {recipe ? <BlocksRenderer content={recipe.directions} /> : <></>}
          </div>
        </div>
        <h4>{textData.moreText}</h4>
        <div className={styles.moreRecipes}>
          {recipes.map((data, index) => (
            <div className={styles.newRecipeWrapper}>
              <RecipePreview data={data} key={index} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecipesPage;
