import React from "react";
import styles from "./RecipePreview.module.scss";
import { useLanguage } from "../../context/Language";

const RecipePreview = ({ data, width }) => {
  const { currentLanguage } = useLanguage();

  return (
    <div style={{ width: width ? width : "100%" }} className={styles.wrapper}>
      <div
        className={styles.thumbnailWrapper}
        onClick={() => {
          window.open(`/recipes/${data.id}`, "_self");
        }}
      >
        <img src={data.image.url} alt="" />
      </div>
      <div className={styles.tags}>
        <div className={styles.tag}>
          <svg
            width="35"
            height="34"
            viewBox="0 0 35 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2_985)">
              <mask
                id="mask0_2_985"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="35"
                height="34"
              >
                <path
                  d="M34.0598 0.530029H0.839844V33.75H34.0598V0.530029Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_2_985)">
                <path
                  d="M17.4501 30.9816C25.0945 30.9816 31.2917 24.7846 31.2917 17.14C31.2917 9.49546 25.0945 3.29834 17.4501 3.29834C9.80552 3.29834 3.6084 9.49546 3.6084 17.14C3.6084 24.7846 9.80552 30.9816 17.4501 30.9816Z"
                  stroke="#EA5452"
                  strokeWidth="1.661"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.4502 8.83496V17.14L22.9869 19.9083"
                  stroke="#EA5452"
                  strokeWidth="1.661"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_2_985">
                <rect
                  width="33.22"
                  height="33.22"
                  fill="white"
                  transform="translate(0.839844 0.530029)"
                />
              </clipPath>
            </defs>
          </svg>
          <p className={styles.infoText}>
            {data.readyMin}{" "}
            {currentLanguage === "en" ? <>MINUTES</> : <>MINUTEN</>}
          </p>
        </div>
        <div className={styles.tag}>
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.1894 15.7558L15.7261 3.29834C16.8273 3.29834 17.8836 3.73585 18.6623 4.51457C19.441 5.29332 19.8786 6.34953 19.8786 7.45084V12.9875H27.7129C28.1142 12.983 28.5116 13.0657 28.8778 13.2301C29.2439 13.3944 29.5699 13.6363 29.8333 13.9391C30.0967 14.242 30.2911 14.5984 30.4028 14.9838C30.5148 15.3692 30.5414 15.7743 30.4814 16.1711L28.5712 28.6287C28.4711 29.2888 28.1358 29.8903 27.627 30.3229C27.1183 30.7552 26.4705 30.9892 25.8028 30.9816H10.1894M10.1894 15.7558V30.9816M10.1894 15.7558H6.03687C5.30266 15.7558 4.59854 16.0475 4.07936 16.5667C3.5602 17.0858 3.26855 17.79 3.26855 18.5242V28.2134C3.26855 28.9476 3.5602 29.6516 4.07936 30.1708C4.59854 30.69 5.30266 30.9816 6.03687 30.9816H10.1894"
              stroke="#EA5452"
              strokeWidth="1.661"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className={styles.infoText}>{data.difficulty}</p>
        </div>
      </div>
      <div
        className={styles.underlineAdder}
        onClick={() => {
          window.open(`/recipes/${data.id}`, "_self");
        }}
      >
        <h4 className={styles.title}>{data.title}</h4>
        <div className={styles.underline} />
      </div>
    </div>
  );
};

export default RecipePreview;
