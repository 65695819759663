import React from "react";

import styles from "./MenuItem.module.scss";
import lieferando from "../../assets/lieferando.png";
import uber from "../../assets/svg/uber.svg";
import wolt from "../../assets/svg/wolt.svg";
import { useParams } from "react-router-dom";
import useFetch from "../../utils/FetchData";
import { getMenuItemById } from "../../utils/api";
import { useLanguage } from "../../context/Language";

import vegan from "../../assets/svg/vegan.svg";
import omnivore from "../../assets/svg/omnivore.svg";
import glutenfree from "../../assets/svg/glutenfree.svg";
import lactosefree from "../../assets/svg/lactosefree.svg";

function foodTypesToArray(foodTypesJSON) {
  if (!foodTypesJSON) {
    return [];
  }

  let tempArray = [];
  for (let object of foodTypesJSON) {
    tempArray.push(object.name);
  }

  console.log(tempArray);
  return tempArray;
}

const MenuItem = () => {
  const { id } = useParams();
  const { currentLanguage } = useLanguage();
  const { data } = useFetch(getMenuItemById, id, currentLanguage);

  console.log(data);

  return (
    <div className={styles.menuItemWrapper}>
      <div className={styles.itemImage}>
        <img src={data?.image?.url} alt="menuItemImage" />
      </div>
      <div className={styles.itemDetails}>
        <div className={styles.itemContent}>
          <a href="/menu">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="24"
              viewBox="0 0 32 24"
              fill="none"
            >
              <path
                d="M31.3413 24C31.0965 24 30.8658 23.853 30.7527 23.6113C30.7148 23.533 27.6984 17.5554 15.1103 17.3521V23.2967C15.1103 23.5646 14.9677 23.8091 14.743 23.9273C14.5187 24.0454 14.2514 24.018 14.0516 23.8552L0.258657 12.5583C0.0961003 12.4251 0 12.219 0 12.0006C0 11.7812 0.0961003 11.5752 0.258657 11.4416L14.0523 0.145168C14.2524 -0.0179249 14.5194 -0.0454883 14.7437 0.0720851C14.9683 0.18943 15.111 0.434984 15.111 0.702155V6.96694C15.4439 6.9443 15.8776 6.92463 16.3895 6.92463C21.0833 6.92463 32 8.52033 32 23.2952C32 23.6221 31.7898 23.9064 31.4917 23.9807C31.4415 23.9945 31.392 24 31.3413 24Z"
                fill="#EB578F"
              />
            </svg>
          </a>
          <div className={styles.title}>
            <h1>{data?.title}</h1>
            <h4>{data?.price}€</h4>
          </div>
          <ul className={styles.content}>
            {data?.ingredients?.split(",")?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          {/* <a href={data?.pdf?.url} className={styles.medical}>
            See medical info
          </a> */}
        </div>
        <div className={styles.buttonWrapper}>
          <div className={styles.certificatesWrapper}>
            <img
              style={{
                filter: `grayscale(${
                  foodTypesToArray(data?.food_types)?.includes("VEGAN") ||
                  foodTypesToArray(data?.food_types)?.includes("VEGETARIAN")
                    ? 0
                    : 1
                })`,
              }}
              src={vegan}
              alt=""
            />
            <img
              style={{
                filter: `grayscale(${
                  foodTypesToArray(data?.food_types)?.includes("OMNIVORE")
                    ? 0
                    : 1
                })`,
              }}
              src={omnivore}
              alt=""
            />
            <img
              style={{
                filter: `grayscale(${
                  foodTypesToArray(data?.food_types)?.includes("GLUTEN FREE")
                    ? 0
                    : 1
                })`,
              }}
              src={glutenfree}
              alt=""
            />
            <img
              style={{
                filter: `grayscale(${
                  foodTypesToArray(data?.food_types)?.includes("LACTOSE FREE")
                    ? 0
                    : 1
                })`,
              }}
              src={lactosefree}
              alt=""
            />
          </div>
          <span>order now</span>
          <div className={styles.buttons}>
            <a
              href="https://wolt.com/en/deu/berlin/restaurant/green-and-protein-berlin"
              className={styles.wolt}
            >
              <img src={wolt} alt="woltLogo" className={styles.companyLogos} />
            </a>
            <a href="http://Lieferando.de" className={styles.lieferando}>
              <img
                src={lieferando}
                alt="lieferandoLogo"
                className={styles.companyLogos}
              />
            </a>{" "}
            <a href="http://Ubereats.com" className={styles.uberEats}>
              <img
                src={uber}
                alt="uberEatsLogo"
                className={styles.companyLogos}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuItem;
