import React from "react";
import styles from "./Privacy.module.scss";
import { useLanguage } from "../../context/Language";

const Privacy = () => {
  const { currentLanguage } = useLanguage();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h2>
          {currentLanguage === "en" ? "Privacy Policy" : "Datenschutzerklärung"}
        </h2>
        <p>
          {currentLanguage === "en"
            ? "Protection of your personal data is a matter of particular importance and high priority to us. With the following privacy policy, we endeavor to explain our data protection regulations in a comprehensible manner and, above all, to inform you of your rights as a data subject."
            : "Der Schutz Ihrer personenbezogenen Daten ist uns ein besonders wichtiges und hohes Anliegen. Mit der nachfolgenden Datenschutzerklärung versuchen wir Ihnen in verständlicher Art und Weise unsere Datenschutzbestimmungen zu erläutern und Sie vor allem über Ihre Rechte als betroffene Person aufzuklären."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "I. Controller according to Art. 4 No. 7 GDPR"
            : "I.	Verantwortlicher gemäß Art. 4 Nr. 7 DSGVO"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "For the processing of personal data within the meaning of the European General Data Protection Regulation (GDPR) and other national data protection laws and regulations, the controller is: Healthy Investment GmbH Breuerstraße 36 51103 Cologne Phone: xxx  E-mail: xxx"
            : "Für die Verarbeitung personenbezogener Daten im Sinne der Europäischen Datenschutz-Grundverordnung (nachfolgend: DSGVO) sowie anderer nationaler Datenschutzgesetze sowie -bestimmungen ist die: Healthy Investment GmbH Breuerstraße 36 51103 Köln Telefon: xxx E-Mail: xxx"}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "II. Definitions"
            : "II.	Begrifflichkeiten"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? 'For the definition of the following terms, "data processing," "processing," "processing of data," the definitions of Art. 4 GDPR shall apply.'
            : "Für die Definition der nachfolgenden Begriffe „Datenverarbeitung“, „Verarbeitung“, „Verarbeitung von Daten“ werden die Definitionen der Art. 4 DSGVO zugrunde gelegt."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "III. Data Processing in General"
            : "III.	Datenverarbeitung im Allgemeinen"}
        </h3>
        <h3>
          {currentLanguage === "en"
            ? "1. Scope of Data Processing"
            : "1.	Umfang der Datenverarbeitung"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "The processing of personal data is generally carried out only to provide a functional website, to display content, and to provide our services. Data processing usually only takes place after obtaining the user's consent. No consent is obtained if this is not possible for factual reasons and/or the processing of the data is permitted by legal regulations and is therefore also lawful without prior consent, or if the data processing is justified by our legitimate interests in accordance with Art. 6 (1) sentence 1 (f) GDPR."
            : "Die Verarbeitung personenbezogener erfolgt grundsätzlich nur, um die Bereitstellung einer funktionsfähigen Internetseite, die Darstellung der Inhalte sowie die Erbringung unserer Leistungen zu ermöglichen. Die Datenverarbeitung erfolgt in der Regel nur nach der Einholung einer Einwilligung des Nutzers. Keine Einwilligung wird eingeholt, wenn dies aus tatsächlichen Gründen nicht möglich ist und/oder die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet und somit auch ohne eine vorherige Einwilligung des betroffenen Nutzers rechtmäßig ist oder wenn die Datenverarbeitung durch unsere berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO gerechtfertigt ist."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "2. Applicable Legal Bases for Data Processing"
            : "2.	Maßgebliche Rechtsgrundlagen der Verarbeitung personenbezogener Daten"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "Unless otherwise stated, the applicable legal bases for the processing of personal data are: •	Consent, Art. 6 (1) sentence 1 (a) GDPR: If we have obtained the consent of the data subject, Art. 6 (1) sentence 1 (a) GDPR forms the legal basis for the processing of their personal data. •	Contract Performance and Pre-contractual Measures, Art. 6 (1) sentence 1 (b) GDPR: If we need to process personal data to fulfill a contract with the data subject or to take pre-contractual measures, Art. 6 (1) sentence 1 (b) GDPR forms the legal basis for data processing. •	Compliance with Legal Obligations, Art. 6 (1) sentence 1 (c) GDPR: If data processing is necessary to comply with legal obligations to which we are subject, Art. 6 (1) sentence 1 (c) GDPR constitutes the legal basis. •	Legitimate Interest, Art. 6 (1) sentence 1 (f) GDPR: If data processing is necessary to safeguard the legitimate interests of our company or a third party and the interests or fundamental rights and freedoms of the data subject that require the protection of personal data do not outweigh these interests, in particular if the data subject is a child, Art. 6 (1) sentence 1 (f) GDPR forms the legal basis."
            : "Sofern nicht anders angegeben, sind die maßgeblichen Rechtsgrundlagen der Verarbeitung personenbezogener Daten die nachfolgenden:•	Einwilligung, Art. 6 Abs. 1 S. 1 lit. a DSGVO, •	wenn wir eine Einwilligung des betroffenen Nutzers eingeholt haben, stellt Art. 6 Abs. S. 1 lit. a DSGVO die Rechtsgrundlage für die Verarbeitung seiner personenbezogenen Daten dar, •	Vertragserfüllung und vorvertragliche Maßnahmen, Art. 6 Abs. 1 S. 1 lit. b DSGVO, •	sofern für vorvertragliche Maßnahmen in Bezug auf die betroffene Person oder zur Erfüllung eines Vertrages mit der betroffenen Person seine personenbezogenen Daten verarbeitet werden müssen, stellt Art. 6 Abs. 1 S. 1 lit. b DSGVO die Rechtsgrundlage der Datenverarbeitung dar, •	Erfüllung rechtlicher Verpflichtungen, Art. 6 Abs. 1 S. 1 lit. c DSGVO, •	wenn die Verarbeitung der Daten zur Erfüllung rechtlicher Verpflichtungen erforderlich ist, der wir unterliegen, stellt Art. 6 lit. c DSGVO die Rechtsgrundlage dar, •	berechtigtes Interesse, Art. 6 Abs. 1 S. 1 lit. f DSGVO, •	ist die Verarbeitung zur Wahrung eines berechtigten Interesse unseres Unternehmens oder eines Dritten erforderlich und überwiegen nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind handelt, so stellt Art. 6 Abs. 1 S. 1 lit. f DSGVO die Rechtsgrundlage der Datenverarbeitung dar."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "3. Disclosure of Personal Data to Third Parties"
            : "3.	Offenbarung personenbezogener Daten gegenüber Dritten"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "To provide and continuously improve our services, personal data is also transmitted to and disclosed to other companies. This includes companies entrusted with IT tasks (e.g., for website hosting or payment processing) and payment institutions that handle payment transactions. We also use other services and products from other companies to ensure, for example, that no fraudulent registrations occur. In any case, we comply with all legal requirements and conclude order processing contracts with the respective companies in accordance with Art. 28 GDPR. If joint responsibility exists, we will ensure through joint controller agreements in accordance with Art. 26 GDPR that the third party complies with the provisions of data protection law."
            : "Um unsere Dienste anzubieten zu können und stets verbessern zu können, werden personenbezogene Daten auch an andere Unternehmen übermittelt und ggf. diesen offengelegt. Hierzu gehören mit IT-Aufgaben beauftragte Unternehmen, die z.B. für das Hosting des Internetangebots zuständig sind oder Zahlungsinstitute, die die Abwicklung der Bezahlvorgänge übernehmen. Wir setzen darüber hinaus noch andere Dienstleistungen und Produkte anderer Unternehmen ein, um beispielsweise zu gewährleisten, dass keine betrügerischen Anmeldungen erfolgen. In jedem Fall halten wir die gesetzlichen Vorgaben ein und schließen mit den entsprechenden Unternehmen Auftragsverarbeitungsverträge gemäß Art. 28 DSGVO ab. Sofern eine gemeinsame Verantwortung vorliegt, werden wir durch sog. Joint-Controller-Verträge gemäß Art. 26 DSGVO sicherstellen, dass das Drittunternehmen die Vorgaben des Datenschutzrechts einhält."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "4. Data Processing in Third Countries"
            : "4.	Datenverarbeitung in Drittländer"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? 'When using the companies mentioned in section IV.5., it may occur that personal data is transmitted to and processed in a third country (i.e., outside the European Union (EU) or the European Economic Area (EEA)). In such cases, this only occurs in accordance with legal requirements. We only process personal data in third countries with a recognized data protection level. This includes only those third countries whose processors are certified under the "Privacy Shield," or on the basis of special guarantees, such as contractual obligations through so-called standard data protection clauses of the EU Commission, the existence of certifications, or binding internal data protection regulations.'
            : 'Bei dem Einsatz der unter IV.5. genannten Unternehmen kann es vorkommen, dass personenbezogene Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR)) übermittelt und dort verarbeitet werden. In solch einem Fall erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. Wir lassen personenbezogene Daten nur in Drittländern mit einem anerkannten Datenschutzniveau verarbeiten. Hierbei handelt es sich nur um Drittländer, die unter dem "Privacy-Shield" zertifizierten US-Verarbeiter gehören, oder auf Grundlage besonderer Garantien, wie z.B. vertraglicher Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, des Vorliegens von Zertifizierungen oder verbindlicher interner Datenschutzvorschriften, verarbeiten.'}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "5. Data Deletion and Storage Duration"
            : "5.	Datenlöschung und Speicherdauer"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "Personal data is deleted as soon as the purpose of storage ceases. Storage may also occur if we are legally obliged to do so. Deletion or blocking of data also occurs if a legal storage period expires. This does not apply if storage of the data is necessary for contract conclusion and/or contract fulfillment with the data subject."
            : "Personenbezogene Daten werden gelöscht, sobald der Speicherungszweck entfällt. Darüber hinaus kann eine Speicherung erfolgen, sofern wir hierzu gesetzlich verpflichtet sind. Eine Löschung oder Sperrung der Daten erfolgt auch dann, wenn eine gesetzliche Speicherfrist besteht. Dies gilt nicht, wenn die Speicherung der Daten für den Vertragsabschluss und/oder zur Vertragserfüllung mit dem Betroffenen erforderlich ist."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "IV. Special Features in Providing This Website"
            : "IV.	Besonderheiten bei der Bereitstellung dieser Internetseite"}
        </h3>
        <h3>
          {currentLanguage === "en"
            ? "1. SSL/TLS Encryption"
            : "1.	SSL- / TLS-Verschlüsselung"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? 'Our website uses SSL/TLS encryption for security reasons, especially to protect the transmission of confidential content, such as orders or inquiries that you send to us as the website operator. You can recognize SSL encryption by the fact that the address line of your browser starts with "https://" and a lock symbol appears in the browser address bar. With SSL encryption, third parties cannot generally read the data during transmission.'
            : "Unsere Seite nutzt aus Sicherheitsgründen, v.a. um bei der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine SSL-Verschlüsselung können Sie daran erkennen, dass in der Adresszeile Ihres Browsers zu Beginn „https://“ steht und darüber hinaus ein Schloss-Symbol in der Browserzeile auftaucht. Bei einer SSL-Verschlüsselung können bei der Datenübertragung Dritte grundsätzlich nicht mitlesen."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "2. Server Log Files"
            : "2.	Server-Logfiles"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "Our website is hosted by the company xxx. When accessing our website, our system automatically collects information from the computer system of the accessing computer. The following data is collected:We only store anonymized IP addresses of website visitors. At the web server level, this is done by replacing, for example, the actual IP address of the visitor (e.g., 123.123.123.123) with an IP address 123.123.123.xxx in the log file, where xxx is a random value between 1 and 254. Establishing a personal reference is no longer possible.The temporary storage of the IP address by the system is necessary to enable the delivery of the website to the user's computer. For this purpose, the user's IP address must be stored for the duration of the session.Log files are stored to ensure the functionality of the website. The data is also used to optimize the website and to ensure the security of our information technology systems. The data is not evaluated for marketing purposes in this context. Our legitimate interest in data processing lies in these purposes in accordance with Art. 6 (1) (f) GDPR.Backups are stored in encrypted form for 14 days. The legal basis for this temporary storage of data and log files is Art. 6 (1) (f) GDPR."
            : "Unsere Website ist bei der Firma xxx gehostet. Beim Aufruf unserer Internetseite erfasst unser System automatisiert Informationen vom Computersystem des aufrufenden Rechners. Folgende Daten werden hierbei erhoben: Bei uns werden nur anonymisierte IP-Adressen von Besuchern der Website gespeichert. Auf Webserver-Ebene erfolgt dies dadurch, dass im Logfile standardmäßig statt der tatsächlichen IP-Adresse des Besuchers z.B. 123.123.123.123 eine IP-Adresse 123.123.123.XXX gespeichert wird, wobei XXX ein Zufallswert zwischen 1 und 254 ist. Die Herstellung eines Personenbezuges ist nicht mehr möglich. Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt. In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO. Backups werden 14 Tage in verschlüsselter Form aufbewahrt. Rechtsgrundlage für diese vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO."}
        </p>
        <h3>{currentLanguage === "en" ? "3.	Cookies" : "3.	Cookies"}</h3>
        <p>
          {currentLanguage === "en"
            ? "Cookies are required for the presentation and use of our website. Cookies are text files that are stored in the Internet browser or by the Internet browser on the user's computer system. When a user accesses our website, a cookie can be stored on the user's operating system. This cookie contains a unique character string that allows unique identification of the user's browser when the website is accessed again. We currently do not use any cookies on our website."
            : "Zur Darstellung und Nutzung unserer Website müssen Cookies eingesetzt werden. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Sobald ein Nutzer unsere Seite aufruft, kann ein Cookie auf dem Betriebssystem dieses Nutzers abgespeichert werden. Dieser Cookie enthält eine individuelle Zeichenfolge, die eine eindeutige Identifizierung des Browsers des Nutzers beim erneuten Aufrufen der Internetseite ermöglicht. Wir setzen auf unserer Internetseite gegenwärtig keine Cookies ein."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "4. Contact Options"
            : "4.	Kontaktmöglichkeiten"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "You can contact us via the e-mail address and telephone number provided in the legal notice.The legal basis for processing the data transmitted when sending an e-mail is Art. 6 (1) sentence 1 (f) GDPR. If the e-mail aims to conclude a contract with us, Art. 6 (1) sentence 1 (b) GDPR also serves as an additional legal basis for processing.The processing of the data you provide to us when contacting us via e-mail serves solely to understand your request and to contact you.Data transmitted via the contact form or by e-mail will be deleted once the purpose of processing has been fulfilled. This is the case when the conversation between the data subject and ourselves has definitively ended. The conversation is deemed ended when it is clearly evident from the circumstances that the matter has been conclusively clarified.In the event of contact via e-mail, the data subject may object to the storage of their personal data at any time. In such a case, however, we will be unable to conduct a conversation with the data subject.The revocation can be declared in text form (e.g., by e-mail), but also verbally or by telephone.All personal data stored in the course of establishing contact will be irrevocably deleted in this case."
            : "Sie können uns über die im Impressum hinterlegte E-Mail-Adresse und Telefonnummer kontaktieren.Die Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Zielt die E-Mail auf den Abschluss eines Vertrages mit uns ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. b DSGVO.Die Verarbeitung der Daten, die Sie uns durch die Kontaktaufnahme per E-Mail zur Verfügung stellen, dient allein dazu, Ihr Anliegen zu erfassen und Sie zu kontaktieren.Die Daten, die über die Eingabemaske des Kontaktformulars, sowie durch die Kontaktaufnahme per E-Mail an uns übermittelt werden, werden gelöscht, nach dem der Zweck ihrer Verarbeitung erreicht ist. Dies ist dann der Fall, wenn die Konversationen zwischen dem Betroffenen und uns endgültig beendet ist. Die Konversation ist dann beendet, wenn sich aus den Umständen eindeutig ergibt, dass der Sachverhalt abschließend geklärt ist.Für den Fall der Kontaktaufnahme durch E-Mail kann der betroffene Nutzer jederzeit der Speicherung seiner personenbezogenen Daten widersprechen. In so einem Fall kann allerdings durch uns keine Konversation mit dem betroffenen Nutzer geführt werden.Der Widerruf kann in Textform, z.B. per E-Mail, aber auch mündlich oder telefonisch erklärt werden.Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem Fall unwiderruflich gelöscht."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "5. Services Used, Service Providers, and Plugins"
            : "5.	Eingesetzte Dienste, Dienstanbieter und Plugins"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? 'Google Fonts: We integrate the fonts (referred to as "Google Fonts" in this privacy policy) of the provider Google.  User data is used solely for the purpose of displaying the fonts in the user\'s browser.  The integration is based on our legitimate interest in the technically secure, maintenance-free, and efficient use of fonts, their uniform presentation, and taking into account possible licensing restrictions for their integration. Service Provider:'
            : 'Google Fonts: Wir binden die Schriftarten (in dieser Datenschutzerklärung "Google Fonts" genannt) des Anbieters Google ein, wobei die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im Browser der Nutzer verwendet werden. Die Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer technisch sicheren, wartungsfreien und effizienten Nutzung von Schriftarten, deren einheitlicher Darstellung sowie unter Berücksichtigung möglicher lizenzrechtlicher Restriktionen für deren Einbindung. Dienstanbieter:'}
        </p>
        <h3>{currentLanguage === "en" ? "V. Your Rights" : "V.	Ihre Rechte"}</h3>
        <h3>
          {currentLanguage === "en"
            ? "1. Right to Information"
            : "1.	Auskunftsrecht"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "You have the right to obtain information as to whether personal data concerning you is being processed. If this is the case, you have a right of access to this personal data."
            : "Sie haben einen Anspruch darauf zu erfahren, ob von ihnen personenbezogene Daten verarbeitet werden. Sollte das der Fall sein, haben Sie einen Anspruch auf Auskunftserteilung über die personenbezogenen Daten."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "2. Right to Rectification"
            : "2.	Recht auf Berichtigung"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "You have the right to obtain from us, as the controller, the rectification and/or completion of inaccurate or incomplete personal data concerning you. We shall rectify the data without undue delay upon request."
            : "Sie haben gegen uns als Verantwortlichen der Daten einen Berichtigungs- und/oder Vervollständigungsanspruch, sofern die von uns verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Die Berichtigung haben wir nach Aufforderung unverzüglich vorzunehmen."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? '3. Right to Erasure ("Right to be Forgotten")'
            : "3.	Recht auf Löschung („Recht auf Vergessenwerden“)"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "You have the right to obtain from us the erasure of personal data concerning you without undue delay, and we are obliged to erase such data without undue delay, where one of the following grounds applies:If the controller has made the personal data public and is obliged pursuant to Article 17(1) GDPR to erase the personal data, the controller, taking account of available technology and the cost of implementation, shall take reasonable steps, including technical measures, to inform controllers which are processing the personal data that the data subject has requested the erasure by such controllers of any links to, or copy or replication of, those personal data.The right to erasure does not apply where processing is necessary."
            : "Sie haben das Recht von uns zu verlangen, dass wir die Sie betreffenden personenbezogene Daten unverzüglich löschen und wir sind verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "4. Right to Restriction of Processing"
            : "4.	Recht auf Einschränkung der Verarbeitung"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "You have the right to request that we, as the data controller, restrict the processing of your personal data if one of the following conditions is met:•	You have disputed the accuracy of your personal data. For the duration that it takes us to verify the accuracy of the disputed personal data, a block will be placed on the processing of your personal data.•	The processing of the personal data proves to be unlawful, and you, as the data subject, object to the deletion of the personal data and instead request the restriction of the use of the personal data.•	We, as the data controller, no longer need the personal data for the purposes of processing, but you, as the data subject, need them for the assertion, exercise, or defense of legal claims.   •	You, as the data subject, have lodged an objection to the processing in accordance with Article 21(1) of the GDPR. As long as it is not yet clear whether the legitimate reasons of the controller outweigh those of the data subject, the processing of the personal data will be blocked.   "
            : "Sie haben das Recht, von uns als Verantwortliche der Datenverarbeitung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:•	Sie haben die Richtigkeit Ihrer personenbezogenen Daten bestritten. Für die Dauer, in der es uns ermöglicht wird, die Richtigkeit der bestrittenen personenbezogenen Daten zu überprüfen, wird eine Sperre hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten vollzogen.•	Die Verarbeitung der personenbezogenen Daten erweist sich als unrechtmäßig und Sie als betroffene Person lehnen die Löschung der personenbezogenen Daten ab und verlangen stattdessen die Einschränkung der Nutzung der personenbezogenen Daten.•	Wir als Verantwortliche der personenbezogenen Daten benötigen diese für die Zwecke der Verarbeitung nicht länger, aber Sie als betroffene Person benötigen diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.•	Sie als betroffene Person haben Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 DSGVO eingelegt. Solange noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen, erfolgt eine Sperre der Verarbeitung der personenbezogenen Daten"}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "5. Right to Object"
            : "5.	Recht auf Widerspruch"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "You have the right, for reasons arising from your particular situation, to object at any time to the processing of personal data concerning you that is carried out on the basis of Article 6(1)(e) or (f) of the GDPR; this also applies to profiling based on these provisions. The controller will no longer process the personal data concerning you unless it can demonstrate compelling legitimate grounds for the processing that outweigh your interests, rights, and freedoms, or if the processing serves the purpose of asserting, exercising, or defending legal claims.If personal data concerning you are processed for the purpose of direct marketing, you have the right to object at any time to the processing of personal data concerning you for the purpose of such advertising; this also applies to profiling insofar as it is associated with such direct marketing.   If you object to the processing for direct marketing purposes, the personal data concerning you will no longer be processed for these purposes.   You have the possibility, in connection with the use of information society services, to exercise your right to object by means of automated proceduresin which technical specifications are used."
            : "Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogener Daten, die aufgrund von Artikel 6 Abs. 1 lit. e oder f erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "6.	Right to data portability"
            : "6.	Recht auf Datenübertragbarkeit"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "You have the right to receive the personal data concerning you that you have provided to the controller in a structured, commonly used, and machine-readable format. You also have the right to transfer this data to another controller without hindrance from the controller to whom the personal data was provided, provided that   •	the processing is based on consent pursuant to Art. 6 (1) (a) GDPR or Art. 9 (2) (a) GDPR or on a contract pursuant to Art. 6 (1) (b) GDPR and   •	the processing is carried out using automated procedures.   In exercising this right, you also have the right to have the personal data concerning you transferred directly from one controller to another controller, insofar as this is technically feasible. The freedoms and rights of other persons must not be impaired thereby.   The right to data portability does not apply to the processing of personal data necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller."
            : "Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern•	die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und•	die Verarbeitung mithilfe automatisierter Verfahren erfolgt.In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "7. Right to Withdraw Consent to Data Protection Declaration"
            : "7.	Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "You have the right to withdraw your consent to the data protection declaration at any time. The withdrawal of consent does not affect the lawfulness of the processing carried out on the basis of the consent until the withdrawal.   "
            : "Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "8. Automated Decision in Individual Cases, Including Profiling"
            : "8.	Automatisierte Entscheidung im Einzelfall einschließlich Profiling"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "You have the right not to be subject to a decision based exclusively on automated processing - including profiling - that has legal effect against you or significantly impairs you in a similar way. This does not apply if the decision   •	is necessary for the conclusion or performance of a contract between you and the controller,•	is permissible under Union or Member State legislation to which the controller is subject and that legislation contains appropriate measures to safeguard your rights and freedoms and your legitimate interests, or   •	is made with your explicit consent.However, these decisions may not be based on special categories of personal data within the meaning of Article 9(1) GDPR, unless Article 9(2)(a) or (g) GDPR applies and appropriate measures have been taken to protect the rights and freedoms and your legitimate interests.   In the cases mentioned in (1) and (3), the controller shall take appropriate measures to safeguard the rights and freedoms and your legitimate interests, which shall include at least the right to obtain the intervention of a person on the part of the controller, to state his or her own position and to contest the decision.   "
            : "Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung•	für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,•	aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder•	mit Ihrer ausdrücklichen Einwilligung erfolgt.Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört."}
        </p>
        <h3>
          {currentLanguage === "en"
            ? "9.	Right to file a complaint with a supervisory authority"
            : "9.	Recht auf Beschwerde bei einer Aufsichtsbehörde"}
        </h3>
        <p>
          {currentLanguage === "en"
            ? "Without prejudice to any other administrative or judicial remedy, you have the right to lodge a complaint with a supervisory authority, in particular in the Member State of your habitual residence, your place of work or the place of the alleged infringement, if you consider that the processing of personal data concerning you infringes the GDPR.   The supervisory authority with which the complaint has been lodged shall inform the complainant on the progress and outcome of the complaint including the possibility of a judicial remedy pursuant to Article 78 GDPR."
            : "Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO."}
        </p>
      </div>
    </div>
  );
};

export default Privacy;
