import React, { useEffect, useState } from "react";
import styles from "./CareerPosition.module.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { getJobposition, getPositionpage } from "../../utils/api";
import { useLanguage } from "../../context/Language";
import Error from "../../Components/Error/Error";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import axios from "axios";

const CareerPosition = () => {
  const positionName = window.location.pathname.split("/")[2];

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formik = useFormik({
    initialValues: { name: "", email: "", number: "", file: null },
    validationSchema: yup.object({
      name: yup.string().required("name is required"),
      email: yup.string().email().required("email is required"),
      number: yup
        .string()
        .matches(phoneRegExp)
        .required("number needs to be valid"),
      file: yup
        .mixed()
        .required()
        .test("fileType", "Only PDF files are accepted", (value) =>
          value ? value.type === "application/pdf" : true
        ),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const formDataToSend = new FormData();
      formDataToSend.append("name", values.name);
      formDataToSend.append("email", values.email);
      formDataToSend.append("number", values.number);
      formDataToSend.append("position", positionName);
      formDataToSend.append("CV", values.file);
      try {
        const response = await axios.post(
          "https://green-and-protein-backend.onrender.com/api/job-application",
          formDataToSend
        );
        console.log(response.data)
        if(response.data.status === "success") {
          window.open("/thank-you", "_self");
        } else {
          if(response.data.error === "failed to upload to cloudinary") {
            window.alert("Your CV file may be corrupted/broken. Please send a working .pdf file")            
          }
          throw new Error(response);          
        }
      } catch {
        console.error("Form submission blocked due to errors");
        window.alert(
          "something went wrong! Please try again later or via our email"
        );
      }
    },
  });

  const [error, setError] = useState(false);
  const [position, setPosition] = useState(undefined);
  const [textData, setTextData] = useState({});
  const { currentLanguage } = useLanguage();

  useEffect(() => {
    //fetch menu items
    const fetchData = async () => {
      try {
        const res = await getJobposition(currentLanguage, positionName);
        const textres = await getPositionpage(currentLanguage);
        setTextData(textres.data);
        setPosition(res.data[0]);
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage]);

  if (error) {
    return <Error />;
  }

  return (
    <div className={styles.wrapper}>
      <h2>{position?.jobTitle}</h2>
      {position ? <BlocksRenderer content={position.jobDescription} /> : <></>}
      <div className={styles.applyWrapper}>
        <div className={styles.content}>
          <h2>
            {currentLanguage === "en" ? (
              <>
                Apply<span> Now</span>
              </>
            ) : (
              <>
                <span>Jetzt</span> Bewerben
              </>
            )}
          </h2>
          <p>{textData.desc}</p>
          <form
            onSubmit={formik.handleSubmit}
            className={styles.entriesWrapper}
          >
            <input
              name="name"
              id="name"
              placeholder={textData.name}
              className={styles.entry}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              required
              style={{
                borderColor:
                  formik.errors.name && formik.touched.name ? "#ff0000" : "",
              }}
            />
            <input
              name="email"
              id="email"
              placeholder={textData.email}
              className={styles.entry}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              required
              style={{
                borderColor:
                  formik.errors.email && formik.touched.email ? "#ff0000" : "",
              }}
            />
            <input
              name="number"
              id="number"
              placeholder={textData.number}
              className={styles.entry}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.number}
              required
              style={{
                borderColor:
                  formik.errors.number && formik.touched.number
                    ? "#ff0000"
                    : "",
              }}
            />
            <input
              type="file"
              id="file"
              name="file"
              className={styles.entry}
              onBlur={formik.handleBlur}
              required
              onChange={(event) => {
                formik.setFieldValue("file", event.currentTarget.files[0]);
              }}
              style={{
                borderColor:
                  formik.errors.file && formik.touched.file ? "#ff0000" : "",
              }}
            />
            <h4 className={styles.fileUploadInfo}>{textData.uploadDetails}</h4>
            <button type="submit" disabled={formik.isSubmitting}>
              {textData.applyButton}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareerPosition;