import React, { useEffect, useState, useMemo } from "react";
import styles from "./Menu.module.scss";
import {
  getMenuItems,
  getMenuCategories,
  getMenuTypes,
  getMenupage,
} from "../../utils/api";
import { useLanguage } from "../../context/Language";
import salad from "../../assets/salad.jpeg";
import lieferando from "../../assets/lieferando.png";
import uber from "../../assets/svg/uber.svg";
import wolt from "../../assets/svg/wolt.svg";

const Menu = () => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [selectedFoodTypes, setSelectedFoodTypes] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [menuCategories, setMenuCategories] = useState([]);
  const [availableFoodTypes, setAvailableFoodTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentLanguage } = useLanguage();
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getMenuCategories(currentLanguage);
        setMenuCategories(categoriesData?.data || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [currentLanguage, menuItems]);

  useEffect(() => {
    const fetchFoodTypes = async () => {
      try {
        const foodTypesData = await getMenuTypes(currentLanguage);
        console.log(foodTypesData);
        const types = foodTypesData?.data || [];
        setAvailableFoodTypes(types);
      } catch (error) {
        console.error("Error fetching food types:", error);
      }
    };
    const fetchPageData = async () => {
      try {
        const res = await getMenupage(currentLanguage);
        setPageData(res.data);
      } catch (error) {
        console.error("Error fetching food types:", error);
      }
    };
    fetchPageData();
    fetchFoodTypes();
  }, [currentLanguage, menuItems]);

  const fetchMenuItems = async (category = "All", page = 1) => {
    setLoading(true);
    try {
      const query =
        category !== "All"
          ? `&filters[menu_category][name][$eq]=${encodeURIComponent(category)}`
          : "";
      const response = await getMenuItems(currentLanguage, page, query);
      if (page > 1) {
        setMenuItems((prevItems) => [...prevItems, ...response.data]);
      } else {
        setMenuItems(response.data);
      }
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchMenuItems(activeCategory);
  }, [activeCategory, currentLanguage]);

  const handleFoodTypeChange = (type) => {
    setSelectedFoodTypes((prevSelected) =>
      prevSelected.includes(type)
        ? prevSelected.filter((t) => t !== type)
        : [...prevSelected, type]
    );
  };

  const filteredMenuItems = useMemo(() => {
    if (selectedFoodTypes.length === 0) {
      return menuItems;
    }
    return menuItems.filter((item) =>
      selectedFoodTypes.some((type) =>
        item.food_types.map((ft) => ft?.name).includes(type)
      )
    );
  }, [menuItems, selectedFoodTypes]);

  return (
    <div className={styles.menuWrapper}>
      <div className={styles.heroSection}>
        <div className={styles.left}>
          <div className={styles.text}>
            <div className={styles.bigText}>{pageData.bigText}</div>
            <div className={styles.subTitle}>{pageData.subTitle}</div>
            <a className={styles.button} href="tel:+4915771348922">
              {pageData.orderNowButton}
            </a>
          </div>
        </div>
        <div className={styles.right}>
          <img src={salad} alt="salad" />
        </div>
      </div>
      <div className={styles.menusButtons}>
        <a
          className={styles.button}
          href={`/pdf/G&P BER MENU_${
            currentLanguage === "en" ? "ENGLISH" : "GERMAN"
          }.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {pageData.dineIn}
        </a>
      </div>
      <div className={styles.menuSection}>
        {menuCategories.length > 0 && (
          <div className={styles.categoryFilters}>
            <div
              className={`${styles.categoryButton} ${
                activeCategory === "All" ? styles.active : ""
              }`}
              onClick={() => setActiveCategory("All")}
            >
              {currentLanguage === "en" ? <>Show All</> : <>Alle Anzeigen</>}
            </div>
            {menuCategories?.map((category) => (
              <div
                key={category?.id}
                className={`${styles.categoryButton} ${
                  activeCategory === category?.name ? styles.active : ""
                }`}
                onClick={() => setActiveCategory(category?.name)}
              >
                {category?.name}
              </div>
            ))}
          </div>
        )}
        <div className={styles.menuItemsWithTypes}>
          <div className={styles.foodTypesFilter}>
            <h2>{pageData.filterBy}:</h2>
            <div className={styles.typeFilterItems}>
              {availableFoodTypes?.length > 0 &&
                availableFoodTypes?.map((type, index) => (
                  <label key={index} className={styles.foodTypeOption}>
                    <input
                      style={{ backgroundImage: `url(${type?.logo?.url})` }}
                      type="checkbox"
                      checked={selectedFoodTypes.includes(type?.name)}
                      onChange={() => handleFoodTypeChange(type?.name)}
                    />
                    <span>{type.name}</span>
                  </label>
                ))}
            </div>
          </div>

          {loading ? (
            <div className={styles.skeletonWrapper}>
              {[...Array(6)].map((_, index) => (
                <MenuItemSkeleton key={index} />
              ))}
            </div>
          ) : (
            <div className={styles.menuItems}>
              {filteredMenuItems?.length > 0 &&
                filteredMenuItems?.map((item) => (
                  <MenuItem key={item.id} {...item} />
                ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <span>
          {currentLanguage === "en" ? "order now" : "jetzt bestellen"}
        </span>
        <div className={styles.buttons}>
          <a
            href="https://wolt.com/en/deu/berlin/restaurant/green-and-protein-berlin"
            className={styles.wolt}
          >
            <img src={wolt} alt="woltLogo" className={styles.companyLogos} />
          </a>
          {/*
          <a href="/uber" className={styles.lieferando}>
            <img
              src={lieferando}
              alt="lieferandoLogo"
              className={styles.companyLogos}
            />
          </a>{" "}
          <a href="/uber" className={styles.uberEats}>
            <img
              src={uber}
              alt="uberEatsLogo"
              className={styles.companyLogos}
            />
          </a>
*/}
        </div>
      </div>
    </div>
  );
};

export default Menu;

const MenuItem = ({ id, title, description, price, image }) => {
  return (
    <a href={`/menu/${id}`} className={styles.menuItem}>
      <div className={styles.itemImage}>
        <img src={image?.url} alt={title} />
      </div>
      <div className={styles.itemContent}>
        <div className={styles.title}>
          <h4>{title}</h4>
          <div className={styles.block} />
        </div>
        <p>{description}</p>
        <span>{price}€</span>
      </div>
    </a>
  );
};

const MenuItemSkeleton = () => {
  return (
    <div className={`${styles.menuItem} ${styles.skeleton}`}>
      <div className={styles.itemImageSkeleton} />
      <div className={styles.itemContent}>
        <div className={styles.titleSkeleton}>
          <div className={styles.titleBlockSkeleton} />
          <div className={styles.blockSkeleton} />
        </div>
        <div className={styles.descriptionSkeleton} />
        <div className={styles.priceSkeleton} />
      </div>
    </div>
  );
};
