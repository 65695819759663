import React, { useEffect, useState } from 'react'
import styles from "./ThankYou.module.scss"
import { useLanguage } from '../../context/Language';
import { getThankYouPage } from '../../utils/api';

const ThankYou = () => {
  const [error, setError] = useState(false);
  const [textData, setTextData] = useState({});
  const { currentLanguage } = useLanguage();

  useEffect(() => {
    //fetch menu items
    const fetchData = async () => {
      try {
        const res = await getThankYouPage(currentLanguage);
        setTextData(res.data);
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage]);

  return (
    <div className={styles.wrapper}>
      <h1>{textData.thankYou}</h1>
      <p>{textData.smallText}</p>
    </div>
  )
}

export default ThankYou