import React, { useEffect, useState } from 'react'
import styles from './Blog.module.scss'
import Blogposts from '../../Components/Blogposts/Blogposts'
import Error from '../../Components/Error/Error';
import { getBlogposts } from '../../utils/api';
import { useLanguage } from '../../context/Language';

const Blog = () => {
  const { currentLanguage } = useLanguage();
  const [error, setError] = useState(false);
  const [blogposts,setBlogposts] = useState([]);

  useEffect(() => {
    //fetch static stuff
    const fetchData = async () => {
      try {
        const blogres = await  getBlogposts(currentLanguage);
        setBlogposts(blogres.data)
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage]);
  
  if (error) {
    return <Error />;
  }
  
  return (
    <div className={styles.wrapper}>
      <h2>{currentLanguage === "de"?<>Blogeinträge</>:<>Blog Posts</>}</h2>
      <Blogposts blogpostData={blogposts} />
    </div>
  )
}

export default Blog
