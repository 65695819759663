import React, { useEffect, useRef, useState } from "react";
import styles from "./Career.module.scss";
import careerImg from "../../assets/careerImg.png";
import { useLanguage } from "../../context/Language";
import { getCareerpage, getFAQ, getJobpositions } from "../../utils/api";
import Error from "../../Components/Error/Error";

const Career = () => {

  const [error,setError] = useState(false)
  const [positions,setPositions] = useState(undefined)
  const [faq,setFaq] = useState(undefined)
  const [textData,setTextData] = useState({})
  const {currentLanguage} = useLanguage()

  useEffect(() => {
    //fetch menu items
    const fetchData = async () => {
      try {
        const res = await getJobpositions(currentLanguage);
        const faqres = await getFAQ(currentLanguage);
        const textres = await getCareerpage(currentLanguage);
        setTextData(textres.data);
        setPositions(res.data);
        setFaq(faqres.data);
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage]);

  if (error) {
    return <Error />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.section1}>
        <div className={styles.content}>
          <h2>{textData.title}</h2>
          {positions?.map((data, index) => (
            <Position data={data} applyText={textData.applyButton} key={index} />
          ))}
        </div>
      </div>
      <div className={styles.section2}>
        <img src={careerImg} alt="" />
        <div className={styles.content}>
          <h2>{textData.faq}</h2>
          <div className={styles.faq}>
            {faq?.map((data, index) => (
              <Shelf key={index} data={data} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Shelf = ({ data }) => {
  const [open,setOpen] = useState(false)
  const [answerHeight,setAnswerHeight] = useState(0)
  const answerRef = useRef(null)
  const [questionHeight,setQuestionHeight] = useState(0)
  const questionRef = useRef(null)
  useEffect(() => {
    setTimeout(() => {
      setAnswerHeight(answerRef.current.getBoundingClientRect().height)
      setQuestionHeight(questionRef.current.getBoundingClientRect().height)    
      window.addEventListener("resize",() => {
        setAnswerHeight(answerRef.current.getBoundingClientRect().height)
        setQuestionHeight(questionRef.current.getBoundingClientRect().height)
      })
    },0)
  },[data])

  return (
    <div className={styles.shelfWrapper} style={{height:open?`${10+questionHeight+answerHeight}px`:`${questionHeight}px`}}>
      <div ref={questionRef} className={styles.question} onClick={() => {setOpen(!open)}}>
        <p>{data.question}</p>
        <svg
          style={{transform:!open?"rotate(180deg)":"rotate(0deg)",transition:"0.4s"}}
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2_209)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.0898 24.5038C19.303 24.5038 24.3398 19.467 24.3398 13.2538C24.3398 7.04059 19.303 2.00378 13.0898 2.00378C6.87665 2.00378 1.83984 7.04059 1.83984 13.2538C1.83984 19.467 6.87665 24.5038 13.0898 24.5038ZM13.0898 25.7538C19.9934 25.7538 25.5898 20.1574 25.5898 13.2538C25.5898 6.35022 19.9934 0.753784 13.0898 0.753784C6.18628 0.753784 0.589844 6.35022 0.589844 13.2538C0.589844 20.1574 6.18628 25.7538 13.0898 25.7538Z"
              fill="#333333"
              fillOpacity="0.3"
            />
              <path
                fillRule="evenodd"
                style={{transition:"0.2s"}}
                clipRule="evenodd"
                d="M13.0898 6.37878C13.435 6.37878 13.7148 6.65859 13.7148 7.00378V19.5038C13.7148 19.849 13.435 20.1288 13.0898 20.1288C12.7446 20.1288 12.4648 19.849 12.4648 19.5038V7.00378C12.4648 6.65859 12.7446 6.37878 13.0898 6.37878Z"
                fill={!open?"#333333":"#fff"}
              />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.21484 13.2538C6.21484 12.9086 6.49465 12.6288 6.83984 12.6288H19.3398C19.685 12.6288 19.9648 12.9086 19.9648 13.2538C19.9648 13.599 19.685 13.8788 19.3398 13.8788H6.83984C6.49465 13.8788 6.21484 13.599 6.21484 13.2538Z"
              fill="#333333"
            />
          </g>
          <defs>
            <clipPath id="clip0_2_209">
              <rect
                width="25"
                height="25"
                fill="white"
                transform="translate(0.589844 0.753784)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <p ref={answerRef} className={styles.answer}>{data.answer}</p>
    </div>
  );
};

const Position = ({ data,applyText }) => {
  return (
    <div className={styles.position}>
      <h3>{data.jobTitle}</h3>
      <div className={styles.tags}>
        <p className={styles.tag}>{data.location}</p>
        <p className={styles.tag}>{data.city}</p>
        <p className={styles.tag}>{data.type}</p>
      </div>
      <p className={styles.description}>{data.jobShortDescription}</p>
      <button onClick={() => window.open(`/career/${data.jobTitle}`,"_self")} >{applyText}</button>
    </div>
  );
};

export default Career;
