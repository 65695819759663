import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import section4Img from "../../assets/section4Img.png";
import section7Img from "../../assets/section7Img.png";
import star from "../../assets/star.png";

//swiper stuff
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css";
import Blogposts from "../../Components/Blogposts/Blogposts";

import {
  getBlogposts,
  getHomepage,
  getMenuCategories,
  getMenuItems,
  getRecipes,
  getReviews,
} from "../../utils/api";
import { useLanguage } from "../../context/Language";
import Error from "../../Components/Error/Error";

const Home = () => {
  //USE THIS FOR API CALL
  const [filter, setFilter] = useState("all");
  const [mobile, setMobile] = useState(window.screen.width < 1080);
  const { currentLanguage } = useLanguage();
  const [error, setError] = useState(false);

  const [textData, setTextData] = useState({});
  const [categories, setCategoriesData] = useState([]);
  const [reviews, setReviews] = useState(undefined);
  const [fooditems, setfoodItems] = useState(undefined);
  const [recipes, setRecipes] = useState("loading");
  const [blogposts, setBlogposts] = useState(undefined);

  window.addEventListener("resize", () =>
    setMobile(window.screen.width < 1080)
  );

  useEffect(() => {
    //fetch static stuff
    const fetchData = async () => {
      try {
        const textres = await getHomepage(currentLanguage);
        const menures = await getMenuCategories(currentLanguage);
        const reviewres = await getReviews(currentLanguage);
        const recipesres = await getRecipes(currentLanguage);
        const blogres = await getBlogposts(currentLanguage);
        setTextData(textres.data);
        setCategoriesData(menures.data);
        setReviews(reviewres.data);
        setRecipes(recipesres.data);
        setBlogposts(blogres.data);
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage]);

  useEffect(() => {
    //fetch menu items
    const fetchData = async () => {
      let query = "";
      if (filter !== "all") {
        query = `&filters[menu_category][name][$eq]=${encodeURIComponent(
          filter
        )}`;
      }

      try {
        const res = await getMenuItems(currentLanguage, 1, query);
        setfoodItems(res.data);
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage, filter]);

  if (error) {
    return <Error />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.hero}>
        <div className={styles.textContent}>
          <h1>{textData.heroTitle}</h1>
          <p>{textData.heroSubtitle}</p>
          <button onClick={() => window.open("/menu", "_self")}>
            {textData.heroButton}
          </button>
        </div>
        <div className={styles.burgerWrapper}>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            modules={[Autoplay]}
            autoplay={{ delay: 3000 }}
            loop={true}
            className={styles.swiperHero}
          >
            {textData?.heroSlider?.map((data, index) => (
              <SwiperSlide key={index} className={styles.item}>
                <img src={data?.url} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={styles.section2}>
        <h2>{textData.menuTitle}</h2>
        <div className={styles.filters}>
          {[{ name: "all" }, ...categories].map((data, index) => (
            <div
              key={index}
              className={
                filter !== data?.name ? styles.filter : styles.selectedFilter
              }
              onClick={() => setFilter(data?.name)}
            >
              {data?.name === "all" && currentLanguage === "de"
                ? "alle"
                : data?.name}
            </div>
          ))}
        </div>
        <Swiper
          slidesPerView={mobile ? 1 : 4}
          spaceBetween={30}
          modules={[Navigation]}
          className={styles.menuPreview}
          navigation={{
            prevEl: `.${styles.menuLeft}`,
            nextEl: `.${styles.menuRight}`,
          }}
        >
          {fooditems?.map((data, index) => (
            <SwiperSlide
              onClick={() => window.open(`/menu/${data?.id}`, "_self")}
              key={index}
              className={styles.item}
            >
              <img src={data?.image?.url} alt="" />
              <h3>{data?.title}</h3>
              <div className={styles.divider} />
              <p>{data?.description}</p>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.scrollButtonsWrapper}>
          <div className={styles.menuLeft}>
            <svg
              width="47"
              height="48"
              viewBox="0 0 36 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="18" cy="18.5" r="18" fill="#DC2268"></circle>
              <path
                d="M20 13.5L14.6 18L20 22.5"
                stroke="white"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
          <div className={styles.menuRight}>
            <svg
              width="47"
              height="48"
              viewBox="0 0 36 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="18" cy="18.5" r="18" fill="#DC2268"></circle>
              <path
                d="M20 14.5L14.6 19L20 23.5"
                transform="rotate(180 18 18.5)"
                stroke="white"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div className={styles.section4}>
        <img className={styles.background} src={section4Img} alt="" />
        <h2>
          {currentLanguage === "en" ? (
            <>
              See what our <span>clients</span> are thinking!
            </>
          ) : (
            <>
              Sehen Sie, was unsere <span>Kunden</span> denken!
            </>
          )}
        </h2>
        <Swiper
          slidesPerView={mobile ? 1 : 2}
          spaceBetween={30}
          modules={[Navigation]}
          className={styles.reviews}
          navigation={{
            nextEl: `.${styles.reviewRight}`,
            prevEl: `.${styles.reviewLeft}`,
          }}
        >
          {reviews?.map((data, index) => (
            <SwiperSlide key={index} className={styles.item}>
              <div className={styles.pfp}>
                <div className={styles.blob} style={{ top: "0", left: "0" }} />
                <img className={styles.avatar} src={data?.avatar?.url} alt="" />
                <div
                  className={styles.blob}
                  style={{ right: "0", bottom: "0" }}
                />
              </div>
              <div className={styles.reviewInfo}>
                <h3>{data?.name}</h3>
                <p>{data?.review}</p>
                <div className={styles.starWrapper}>
                  {[true, true, true, true, true].map((_, index) => (
                    <img
                      src={star}
                      alt="star"
                      key={index}
                      style={{ opacity: index < data?.stars ? "1" : "0.3" }}
                    />
                  ))}
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className={styles.reviewLeft}>
            <svg
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="18" cy="18" r="18" fill="#87E874"></circle>
              <path
                d="M20 13.5L14.6 18L20 22.5"
                stroke="black"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
          <div className={styles.reviewRight}>
            <svg
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="18" cy="18" r="18" fill="#87E874"></circle>
              <path
                d="M20 14.5L14.6 19L20 23.5"
                stroke="black"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
        </Swiper>
      </div>
      <div className={styles.section5}>
        <h2>{textData.mealPlansTitle}</h2>
        <p>{textData.recepiesText}</p>
        <div className={styles.recipesWrapper}>
          {[recipes[0], recipes[1], recipes[2], recipes[3], recipes[4]].map(
            (data, index) => {
              //incase there are less than 5 reviews, return nothing
              if (!data) {
                return <></>;
              }

              return (
                <div
                  key={index}
                  className={styles.recipe}
                  style={{ display: mobile ? (index > 1 ? "none" : "") : "" }}
                  onClick={() => {
                    window.open(`/recipes/${data?.id}`, "_self");
                  }}
                >
                  <img
                    className={styles.recipeImg}
                    src={data?.image?.url}
                    alt=""
                  />
                  <p>{data?.title}</p>
                </div>
              );
            }
          )}
        </div>
        <div
          onClick={() => window.open("/recipes", "_self")}
          className={styles.viewRecipesWrapper}
        >
          <p>{textData.recepiesButtonText}</p>
          <svg
            width="25"
            height="16"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1_136)">
              <path
                d="M17.1018 1.63135L15.7378 3.02538L19.8869 7.17163H0.450867V9.10913H19.8869L15.7387 13.2564L17.1018 14.6494L23.6089 8.14038L17.1018 1.63135Z"
                fill="#85E573"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_136">
                <rect
                  width="24"
                  height="15"
                  fill="white"
                  transform="translate(0.0299683 0.640137)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className={styles.section6}>
        <h2>{textData?.blogTitle}</h2>
        <div className={styles.blogpostsWrapper}>
          <Blogposts
            blogpostData={
              blogposts ? [blogposts[0], blogposts[1]] : [undefined, undefined]
            }
          />
        </div>
      </div>
      <div className={styles.section7}>
        <img src={section7Img} alt="" />
        <h2>{textData?.ctaTitle}</h2>
        <p>{textData?.ctaText}</p>
        <button
          onClick={() => {
            window.open("/career", "_self");
          }}
        >
          {textData?.ctaButton}
        </button>
      </div>
      <div className={styles.section8}>
        <h2>{textData?.locationText}</h2>

        <iframe
          title="Green & Protein Berlin"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2427.4071620913023!2d13.385841876927625!3d52.52526453583942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85100682d3447%3A0xef7393fd8d365499!2sGreen%20%26%20Protein%20Am%20Tacheles!5e0!3m2!1sen!2s!4v1741358108146!5m2!1sen!2s"
          style={{ border: 0 }}
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Home;
