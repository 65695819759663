import React, { useEffect, useRef, useState } from "react";
import styles from "./Header.module.scss";

import suitcaseLogo from "../../assets/suitcaseLogo.svg";
import contractLogo from "../../assets/contractLogo.svg";
import saladLogo from "../../assets/saladLogo.svg";
import healthyLogo from "../../assets/healthyLogo.svg";
import USFlag from "../../assets/USFlag.svg";
import DEFlag from "../../assets/DEFlag.svg";
import { useLanguage } from "../../context/Language";
import { getHeader } from "../../utils/api";
import Error from "../Error/Error";

const Header = () => {
  const currentPath = window.location.pathname;
  const [dropdownOpened, setdropdownOpened] = useState(false);
  const [languageOpened, setlanguageOpened] = useState(false);
  const [shelf,setShelf] = useState(false)
  const [moreDropHeight, setMoreHeight] = useState(0);
  const [languageDropHeight, setLanguageHeight] = useState(0);

  const moreDropRef = useRef();
  const languageDropRef = useRef();

  useEffect(() => {
    moreDropRef.current.addEventListener("mouseenter", () => {
      setdropdownOpened(true);
    });
    moreDropRef.current.addEventListener("mouseleave", () => {
      setdropdownOpened(false);
    });

    languageDropRef.current.addEventListener("mouseenter", () => {
      setlanguageOpened(true);
    });
    languageDropRef.current.addEventListener("mouseleave", (e) => {
      setlanguageOpened(false);
    });
    window.addEventListener("touchend",(e) => {
      if(e.target.id === "languageDropdown") {
        setlanguageOpened(true)
      }
      else {
        setlanguageOpened(false)
      }
    },[])


    //if you are reading this and wondering what the FUCK is going on here,
    //i am getting the amount of children that the dropdowns have and storing it
    //in a useState to update the height of the dropdown according how many children
    //are in the dropdown list. The reason is because the height needs to be specified
    //for the dropdown transition to play. this way if a developer adds a new
    //child/option to the dropdown, they wont need to manually update the height of
    //the dropdown for the child to show up.

    //is this a very ugly looking way of doing it? Yes. Does it work perfectly? Also yes.

    setMoreHeight(moreDropRef.current.children[1].children[1].children.length);
    setLanguageHeight(
      languageDropRef.current.children[1].children[1].children.length
    );
  }, []);

  const { currentLanguage, setLanguage } = useLanguage();
  const [error,setError] = useState(false)
  const [textData,setTextData] = useState("loading")

  useEffect(() => {
    //fetch menu items
    const fetchData = async () => {
      try {
        const res = await getHeader(currentLanguage);
        setTextData(res.data);
        sessionStorage.setItem(`Header-${currentLanguage}`,JSON.stringify(res.data))
        console.log("saved header data to sessionStorage")
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {

      if(sessionStorage.getItem(`Header-${currentLanguage}`) === null) {
        fetchData();
      }
      else {
        console.log("retrieved header data from sessionStorage")
        setTextData(JSON.parse(sessionStorage.getItem(`Header-${currentLanguage}`)))
      }

    }
  }, [currentLanguage]);

  if (error) {
    return <Error />;
  }

  return (
    <div className={styles.wrapper}>
      <Logo />
      <a
        className={currentPath !== "/" ? styles.option : styles.selectedOption}
        href="/"
      >
        {textData.home}
      </a>
      <a
        className={
          currentPath !== "/menu" ? styles.option : styles.selectedOption
        }
        href="/menu"
      >
        {textData.menu}
      </a>
      <a
        className={
          currentPath !== "/contact-us" ? styles.option : styles.selectedOption
        }
        href="/contact-us"
      >
        {textData.contact}
      </a>
      <div
        className={styles.dropdown}
        id="headerDropdown"
        ref={moreDropRef}
        style={{
          background: dropdownOpened ? "#fbd6da65" : "unset",
        }}
      >
        {textData.more}
        <svg
          className={styles.dropdownArrow}
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
        >
          <path d="M0.798096 1L4.7981 5L8.7981 1" stroke="black"></path>
        </svg>
        <div
          className={styles.dropdownMenu}
          style={{
            height: dropdownOpened ? `${39 + 42 * moreDropHeight}px` : "0px",
          }}
        >
          <div className={styles.hitbox} id="headerHitbox" />
          <div className={styles.options}>
            <DropdownItem
              image={suitcaseLogo}
              text={textData.careers}
              onClickFunc={() => window.open("/career", "_self")}
              id={"headerDropdown"}
              selected={currentPath === "/career"}
            />
            <DropdownItem
              image={contractLogo}
              text={textData.blog}
              onClickFunc={() => window.open("/blog", "_self")}
              id={"headerDropdown"}
              selected={currentPath === "/blog"}
            />
            <DropdownItem
              image={saladLogo}
              text={textData.recipes}
              onClickFunc={() => window.open("/recipes", "_self")}
              id={"headerDropdown"}
              selected={currentPath === "/recipes"}
            />
            <DropdownItem
              image={healthyLogo}
              text={textData.about}
              onClickFunc={() => window.open("/about-us", "_self")}
              id={"headerDropdown"}
              selected={currentPath === "/about-us"}
            />
          </div>
        </div>
      </div>
      <div
        className={styles.dropdown}
        id="languageDropdown"
        ref={languageDropRef}
        style={{
          background: languageOpened ? "#fbd6da65" : "unset",
          right: "3%",
          position: "absolute",
          display:"flex"
        }}
      >
        {currentLanguage}
        <svg
          className={styles.dropdownArrow}
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
        >
          <path d="M0.798096 1L4.7981 5L8.7981 1" stroke="black"></path>
        </svg>
        <div
          className={styles.dropdownMenu}
          style={{
            height: languageOpened
              ? `${39 + 42 * languageDropHeight}px`
              : "0px",
          }}
        >
          <div className={styles.hitbox} id="languageHitbox" />
          <div className={styles.options}>
            <DropdownItem
              image={DEFlag}
              text={"DE"}
              onClickFunc={() => {
                setLanguage("de");
              }}
              id={"dropdownLanguage"}
              selected={currentLanguage === "de"}
            />
            <DropdownItem
              image={USFlag}
              text={"EN"}
              onClickFunc={() => {
                setLanguage("en");
              }}
              id={"dropdownLanguage"}
              selected={currentLanguage === "en"}
            />
          </div>
        </div>
      </div>
      <div className={styles.shelfIcon} onClick={() => setShelf(!shelf)}>
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
      </div>
      <div className={styles.shelf} style={{height:shelf?"calc(100dvh - 60px)":"0px"}} >
        <a href="/">{textData.home}</a>
        <a href="/menu">{textData.menu}</a>
        <a href="/contact-us">{textData.contact}</a>
        <a href="/career">{textData.careers}</a>
        <a href="/blog">{textData.blog}</a>
        <a href="/recipes">{textData.recipes}</a>
        <a href="/about-us">{textData.about}</a>
      </div>
    </div>
  );
};

export default Header;

const DropdownItem = ({ image, text, onClickFunc, id, selected }) => {
  return (
    <div
      onClick={onClickFunc}
      className={styles.dropdownItem}
      id={id}
      style={{ borderLeft: selected ? "4px solid #eb57bf" : "" }}
    >
      <img src={image} alt="" />
      <p>{text}</p>
    </div>
  );
};

//this shit is WAAY too much so im making it a component to make the code more clean
const Logo = () => {
  return (
    <svg
      className={styles.logo}
      width="185"
      height="38"
      viewBox="0 0 185 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => window.open("/", "_self")}
    >
      <g clipPath="url(#clip0_11_28)">
        <path
          d="M88.3944 14.2682C88.2339 14.5665 88.3944 15.5465 89.5744 14.7206C91.2329 13.5602 94.1828 10.5579 93.6747 6.54277C92.7046 -1.09099 81.9144 -1.98907 79.1808 3.55682C79.0169 3.88447 78.5319 3.84193 78.45 3.48469C78.2009 2.41283 76.9914 1.80647 75.6868 4.97923C74.1857 8.62077 75.9523 12.5147 77.6109 14.3961C77.9387 14.7697 77.8436 15.3433 77.4143 15.5924C75.0149 16.9887 68.8464 21.2136 71.19 29.1194C73.4155 36.6384 83.94 39.1819 89.5285 35.4289C89.5842 35.3928 89.6563 35.4157 89.6826 35.478C90.0333 36.327 91.1477 37.3823 94.1893 36.0582C99.5025 33.7475 102.492 23.3211 93.8288 13.7536C93.7468 13.6618 93.5962 13.6913 93.5502 13.8093C92.8946 15.5039 91.1772 16.8969 85.4936 18.0638C75.6737 20.0827 77.765 30.804 85.2413 31.2105C92.4097 31.6039 94.114 23.5506 94.4188 19.696C94.4253 19.5977 94.5729 19.601 94.5827 19.6993C96.1035 38.6508 75.2116 35.6354 75.8507 24.1602C76.1491 18.8111 80.9737 16.7068 82.1635 16.2151C82.4455 16.0972 82.9568 15.7169 82.3995 15.2121C81.8784 14.6845 80.433 13.4192 80.4297 9.85311C80.4264 6.65745 82.9338 4.20571 85.474 4.22208C88.9647 4.25166 91.8851 7.8407 88.3944 14.2682Z"
          fill="#87E873"
        />
        <path
          d="M14.1595 16.3912C14.1595 15.3095 13.2648 14.4279 12.1667 14.4279C11.5506 14.4279 10.9867 14.6999 10.6164 15.1752L10.5148 15.3063L10.3673 15.2276C9.36757 14.6868 8.23354 14.3984 7.07973 14.3984C3.17607 14.3984 0 17.5745 0 21.4782C0 25.3524 3.17607 28.5284 7.07973 28.558C7.98769 28.558 8.88911 28.381 9.76094 28.0336L10.1805 27.8664L10.0166 28.286C9.69209 29.1249 9.06937 29.7641 8.26299 30.0854C7.46001 30.4032 6.5586 30.377 5.79816 30.0133C5.52944 29.8854 5.24429 29.8232 4.95585 29.8232C4.72969 29.8232 4.50025 29.8625 4.28066 29.9444C3.78244 30.1247 3.38585 30.4886 3.16296 30.9736C2.72375 31.9537 3.15312 33.127 4.12005 33.5827C5.05418 34.0219 6.06037 34.2448 7.10605 34.2448C10.9933 34.2448 14.1562 31.0949 14.1562 27.2239V16.3912H14.1595ZM7.07973 24.3724C5.46717 24.3724 4.1561 23.0743 4.1561 21.4782C4.1561 19.8656 5.46717 18.5544 7.07973 18.5544C8.676 18.5544 9.97392 19.8656 9.97392 21.4782C9.9772 23.0743 8.676 24.3724 7.07973 24.3724Z"
          fill="#87E873"
        />
        <path
          d="M20.1361 20.4849C20.1361 19.4361 21.0178 18.5871 22.1454 18.5543C23.3483 18.5281 24.2923 17.6792 24.3414 16.5811C24.3677 16.0142 24.1644 15.4569 23.7842 15.0505C23.4204 14.6605 22.9288 14.4408 22.4011 14.4277C21.975 14.4277 20.6606 14.4277 19.4838 15.0308L19.3659 15.0899L19.2643 15.0046C18.8873 14.6834 18.4416 14.513 17.9729 14.513C16.9076 14.513 16.0391 15.3815 16.0391 16.4468V26.2798C16.0391 27.4271 16.9405 28.3283 18.0876 28.3283C19.238 28.3283 20.1361 27.4271 20.1361 26.2798V20.4849Z"
          fill="#87E873"
        />
        <path
          d="M38.0988 20.5866C37.6596 17.0599 34.6507 14.3984 31.1042 14.3984C28.2362 14.3984 25.6863 16.0897 24.6079 18.7085C23.8377 20.5637 23.8671 22.7663 24.6899 24.4576C25.9058 26.9846 28.5084 28.5514 31.4746 28.5514C32.884 28.5514 34.2541 28.1875 35.4406 27.4992C36.2601 27.0142 36.5354 25.9358 36.0502 25.0967C35.8109 24.6935 35.4079 24.3953 34.9489 24.2839C34.5097 24.1756 34.0575 24.2478 33.6805 24.4838C31.8941 25.4606 29.3704 25.3425 27.9838 23.2317L27.7839 22.9269H35.7061C36.5616 22.9269 37.2368 22.6844 37.6498 22.2256C38.0168 21.8256 38.1676 21.2749 38.0988 20.5866ZM27.9315 20.0818L27.9872 19.8394C28.2429 18.7545 29.4982 17.5843 31.0781 17.5843C32.6776 17.5843 33.9067 18.7315 34.1689 19.8394L34.2246 20.0818H27.9315Z"
          fill="#87E873"
        />
        <path
          d="M53.302 20.5866C52.8628 17.0599 49.8538 14.3984 46.3074 14.3984C43.4394 14.3984 40.8894 16.0897 39.811 18.7085C39.0407 20.5637 39.0703 22.7663 39.8929 24.4576C41.1091 26.9846 43.7115 28.5514 46.6778 28.5514C48.0871 28.5514 49.4573 28.1875 50.6437 27.4992C51.4632 27.0142 51.7385 25.9358 51.2535 25.0967C51.0142 24.6935 50.611 24.3953 50.1521 24.2839C49.713 24.1756 49.2605 24.2478 48.8837 24.4838C47.0973 25.4606 44.5735 25.3425 43.187 23.2317L42.9872 22.9269H50.9093C51.7648 22.9269 52.44 22.6844 52.8529 22.2256C53.2233 21.8256 53.3709 21.2749 53.302 20.5866ZM43.1346 20.0818L43.1903 19.8394C43.446 18.7545 44.7014 17.5843 46.2811 17.5843C47.8807 17.5843 49.1097 18.7315 49.3721 19.8394L49.4277 20.0818H43.1346Z"
          fill="#87E873"
        />
        <path
          d="M66.4788 20.425C66.4788 17.085 63.7618 14.3711 60.425 14.3711C59.4974 14.3711 58.5732 14.5809 57.7537 14.9774L57.6456 15.0299L57.5505 14.9545C57.1801 14.6694 56.7376 14.5121 56.305 14.5121C55.22 14.5121 54.3711 15.3741 54.3711 16.4754V26.2789C54.3711 27.4097 55.2889 28.3275 56.4196 28.3275C57.5505 28.3275 58.4682 27.4097 58.4682 26.2789V20.425C58.4682 19.3598 59.3467 18.4945 60.425 18.4945C61.5066 18.4945 62.3556 19.3433 62.3556 20.425V26.2789C62.3556 27.3901 63.3061 28.3275 64.4336 28.3275C65.5645 28.3275 66.4821 27.4097 66.4821 26.2789V20.425H66.4788Z"
          fill="#87E873"
        />
        <path
          d="M110.732 14.2871C109.542 14.2871 108.346 14.592 107.274 15.1722L107.14 15.2442L107.035 15.1328C106.655 14.7263 106.156 14.5133 105.586 14.5133C104.537 14.5133 103.652 15.3982 103.652 16.447V30.8263C103.652 31.8752 104.482 32.7372 105.54 32.7896H105.73C106.861 32.7896 107.779 31.8718 107.779 30.741V27.8076L108.048 27.9157C108.92 28.2631 109.824 28.4401 110.732 28.4401C114.636 28.4401 117.812 25.2641 117.812 21.3603C117.812 17.4566 114.633 14.2871 110.732 14.2871ZM110.732 24.3168C109.077 24.3168 107.779 23.0189 107.779 21.3636C107.779 19.7084 109.077 18.4105 110.732 18.4105C112.387 18.4105 113.685 19.7084 113.685 21.3636C113.685 23.0189 112.387 24.3168 110.732 24.3168Z"
          fill="#87E873"
        />
        <path
          d="M123.081 20.4849C123.081 19.4361 123.963 18.5871 125.09 18.5543C126.293 18.5281 127.237 17.6792 127.286 16.5811C127.312 16.0142 127.109 15.4569 126.729 15.0505C126.365 14.6605 125.873 14.4408 125.346 14.4277C124.92 14.4277 123.605 14.4277 122.429 15.0308L122.311 15.0899L122.209 15.0046C121.832 14.6834 121.386 14.513 120.914 14.513C119.849 14.513 118.98 15.3815 118.98 16.4468V26.2798C118.98 27.4271 119.882 28.3283 121.029 28.3283C122.176 28.3283 123.078 27.4271 123.078 26.2798V20.4849H123.081Z"
          fill="#87E873"
        />
        <path
          d="M134.286 14.3984C130.383 14.3984 127.207 17.5745 127.207 21.4782C127.207 25.3819 130.383 28.558 134.286 28.558C138.19 28.558 141.367 25.3819 141.367 21.4782C141.367 17.5745 138.19 14.3984 134.286 14.3984ZM134.286 24.4313C132.631 24.4313 131.334 23.1334 131.334 21.4782C131.334 19.8229 132.631 18.525 134.286 18.525C135.942 18.525 137.24 19.8229 137.24 21.4782C137.24 23.1334 135.942 24.4313 134.286 24.4313Z"
          fill="#87E873"
        />
        <path
          d="M147.898 24.6883H147.471C146.511 24.6883 146.082 23.8919 146.082 23.0987V18.5821H148.353C149.343 18.5821 150.146 17.7921 150.146 16.8187C150.146 15.8124 149.36 15.0258 148.353 15.0258H146.082V12.5839C146.082 11.44 145.15 10.5059 144.004 10.5059C142.857 10.5059 141.926 11.4367 141.926 12.5839V23.0987C141.926 26.3731 144.309 28.8412 147.468 28.8412H147.894C149.038 28.8412 149.972 27.9103 149.972 26.7632C149.976 25.6192 149.042 24.6883 147.898 24.6883Z"
          fill="#87E873"
        />
        <path
          d="M165.146 20.5866C164.707 17.0599 161.697 14.3984 158.151 14.3984C155.284 14.3984 152.734 16.0897 151.655 18.7085C150.885 20.5637 150.913 22.7663 151.737 24.4576C152.953 26.9846 155.555 28.5514 158.522 28.5514C159.93 28.5514 161.301 28.1875 162.487 27.4992C163.306 27.0142 163.579 25.9358 163.096 25.0967C162.858 24.6935 162.454 24.3953 161.996 24.2839C161.557 24.1789 161.104 24.2478 160.728 24.4838C158.94 25.4606 156.417 25.3425 155.031 23.2317L154.831 22.9269H162.753C163.608 22.9269 164.284 22.6844 164.697 22.2256C165.064 21.8256 165.214 21.2749 165.146 20.5866ZM154.978 20.0818L155.037 19.8394C155.293 18.7545 156.549 17.5843 158.128 17.5843C159.727 17.5843 160.957 18.7315 161.216 19.8394L161.272 20.0818H154.978Z"
          fill="#87E873"
        />
        <path
          d="M170.754 16.4237C170.754 15.2797 169.822 14.3457 168.676 14.3457C167.532 14.3457 166.598 15.2764 166.598 16.4237V26.2567C166.598 27.4007 167.528 28.3348 168.676 28.3348C169.82 28.3348 170.754 27.4039 170.754 26.2567V16.4237Z"
          fill="#87E873"
        />
        <path
          d="M168.673 13.3287C169.63 13.3287 170.41 12.5354 170.41 11.5652C170.41 10.6246 169.614 9.82812 168.673 9.82812C167.7 9.82812 166.91 10.6082 166.91 11.5652C166.91 12.5354 167.7 13.3287 168.673 13.3287Z"
          fill="#87E873"
        />
        <path
          d="M184.997 20.425C184.997 17.085 182.281 14.3711 178.943 14.3711C178.016 14.3711 177.092 14.5809 176.272 14.9774L176.164 15.0299L176.069 14.9545C175.699 14.6694 175.256 14.5121 174.824 14.5121C173.739 14.5121 172.891 15.3741 172.891 16.4754V26.2789C172.891 27.4097 173.808 28.3275 174.939 28.3275C176.069 28.3275 176.987 27.4097 176.987 26.2789V20.425C176.987 19.3598 177.866 18.4945 178.943 18.4945C180.026 18.4945 180.874 19.3433 180.874 20.425V26.2789C180.874 27.3901 181.824 28.3275 182.952 28.3275C184.084 28.3275 185.001 27.4097 185.001 26.2789V20.425H184.997Z"
          fill="#87E873"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_28">
          <rect width="185" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
