import React, { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import { useLanguage } from "../../context/Language";
import { getFooter } from "../../utils/api";
import Error from "../Error/Error";

const Footer = () => {
  const [email, setEmail] = useState("");

  function subscribe() {
    //check that email is valid here

    console.log("subscribe clicked"); //remove later
  }

  const [error, setError] = useState(false);
  const [textData, setTextData] = useState("loading");
  const { currentLanguage } = useLanguage();

  useEffect(() => {
    //fetch menu items
    const fetchData = async () => {
      try {
        const res = await getFooter(currentLanguage);
        setTextData(res.data);
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage]);

  if (error) {
    return <Error />;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <h2>{textData.subscribe}</h2>
          <div className={styles.inputWrapper}>
            <input
              placeholder={textData.writeEmail}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div onClick={subscribe} className={styles.enterButton}>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18" cy="18.5" r="18" fill="#87E874" />
                <path
                  d="M20 14.5L14.6 19L20 23.5"
                  transform="rotate(180 18 18.5)"
                  stroke="white"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.left}>
            <FooterLogo />
            <p>{textData.HealthyAndTasty}</p>
            <div className={styles.socials}>
              <svg
                onClick={() =>
                  window.open("https://www.facebook.com/greenandprotein.ks")
                }
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1_319)">
                  <path
                    d="M16.3002 24.3723V15.0911H19.3379C19.4878 13.8807 19.6377 12.7016 19.7973 11.4637H16.3167C16.3167 10.444 16.2687 9.4807 16.3324 8.51894C16.3811 7.79667 16.9148 7.40541 17.7392 7.37491C18.4475 7.3505 19.158 7.36957 19.9255 7.36957V6.50773C19.9255 5.8091 19.9345 5.10971 19.9158 4.41109C19.9158 4.30278 19.7839 4.10601 19.7097 4.10601C18.5389 4.07627 17.3525 3.94203 16.2005 4.08999C14.1124 4.3577 12.7685 5.93113 12.6404 8.07124C12.5834 9.03376 12.6059 10.0016 12.5931 10.9695C12.5931 11.1182 12.5931 11.2669 12.5931 11.4561H9.53442V15.0751H12.5706V24.3799H12.112C9.6391 24.3799 7.16574 24.3799 4.69188 24.3799C2.05214 24.3707 0.0239841 22.3527 0.013491 19.6611C-0.004497 14.8058 -0.004497 9.95001 0.013491 5.0937C0.0232346 2.43953 2.00342 0.408484 4.62218 0.396281C9.39351 0.374417 14.1653 0.374417 18.9377 0.396281C21.5489 0.410772 23.5351 2.43038 23.5486 5.09903C23.5726 9.95433 23.5726 14.8102 23.5486 19.6665C23.5336 22.3809 21.5002 24.3692 18.8222 24.3738C17.9888 24.3738 17.1546 24.3723 16.3002 24.3723Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_319">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0 0.379883)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <svg
                onClick={() =>
                  window.open("https://www.instagram.com/greenandprotein.de/")
                }
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1_324)">
                  <mask
                    id="mask0_1_324"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="25"
                  >
                    <path
                      d="M24 0.379883H0V24.3799H24V0.379883Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_1_324)">
                    <path
                      d="M17.863 24.3798H6.15097C6.07541 24.3657 5.99984 24.3469 5.9238 24.3385C5.26669 24.2667 4.62601 24.1211 4.03133 23.8399C1.45499 22.6213 0.0661367 20.5811 0.0262407 17.7269C-0.0235119 14.1662 0.0121598 10.6037 0.0154453 7.04251C0.0154453 5.88863 0.27031 4.78921 0.856546 3.79447C2.17875 1.55337 4.15946 0.404193 6.76209 0.39058C10.2598 0.371802 13.7556 0.38166 17.2557 0.39058C17.7058 0.39058 18.1667 0.41546 18.6046 0.512165C21.3208 1.11164 23.0575 2.76546 23.8033 5.44595C23.9005 5.79662 23.9357 6.16466 24 6.52566V18.241C23.961 18.486 23.9352 18.7343 23.8807 18.9761C23.2706 21.6946 21.6245 23.4357 18.9388 24.1836C18.5891 24.2807 18.2221 24.3159 17.863 24.3798ZM12.0246 2.5129C10.2842 2.5129 8.54348 2.5129 6.80245 2.5129C6.25423 2.5129 5.71259 2.5697 5.19159 2.75654C3.29302 3.43582 2.14589 5.07087 2.14073 7.13405C2.13462 10.6323 2.12899 14.1287 2.15011 17.6293C2.14877 18.1594 2.23097 18.6865 2.39371 19.1911C3.03815 21.1003 4.6936 22.2471 6.75364 22.2509C10.2509 22.2575 13.7472 22.2626 17.2449 22.242C17.7747 22.2439 18.3015 22.1623 18.806 22.0002C20.7088 21.3599 21.8615 19.7164 21.8686 17.6659C21.8808 14.1442 21.8808 10.6224 21.8686 7.10072C21.8653 5.52763 21.2312 4.26015 19.9512 3.33442C19.1388 2.74668 18.2146 2.51056 17.2214 2.51196C15.4901 2.51478 13.7578 2.51509 12.0246 2.5129Z"
                      fill="white"
                    />
                    <path
                      d="M18.1888 12.3846C18.186 15.7828 15.3895 18.576 11.9993 18.5657C8.59691 18.5558 5.80889 15.7565 5.82625 12.3691C5.84362 8.96989 8.61287 6.20396 12.0036 6.2002C15.3942 6.19645 18.1917 8.9896 18.1888 12.3846ZM11.9965 8.33192C9.75389 8.34788 7.94355 10.1688 7.95998 12.393C7.97547 14.6365 9.79613 16.4476 12.0195 16.4325C14.2617 16.417 16.0729 14.5956 16.056 12.3714C16.0406 10.1285 14.2222 8.31643 11.9965 8.33192Z"
                      fill="white"
                    />
                    <path
                      d="M18.4586 7.51185C18.1508 7.51305 17.8495 7.42287 17.593 7.25271C17.3365 7.08255 17.1362 6.84008 17.0176 6.55601C16.899 6.27193 16.8673 5.95903 16.9266 5.65694C16.9859 5.35485 17.1335 5.07716 17.3508 4.85905C17.568 4.64093 17.845 4.4922 18.1468 4.4317C18.4487 4.37119 18.7616 4.40163 19.0461 4.51916C19.3306 4.63669 19.5738 4.83603 19.745 5.09192C19.9161 5.34781 20.0075 5.64874 20.0075 5.9566C20.0036 6.36682 19.8393 6.75923 19.5499 7.04988C19.2604 7.34053 18.8687 7.50635 18.4586 7.51185Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_1_324">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0 0.379883)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <svg
                onClick={() =>
                  window.open("https://www.tiktok.com/@greenandprotein")
                }
                width="22"
                height="25"
                viewBox="0 0 22 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1_335)">
                  <mask
                    id="mask0_1_335"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="22"
                    height="25"
                  >
                    <path
                      d="M21.3333 0.379883H0V24.3799H21.3333V0.379883Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_1_335)">
                    <path
                      d="M16.7996 4.13988C15.8881 3.09938 15.3858 1.76312 15.3862 0.379883H11.2662V16.9132C11.2344 17.8079 10.8567 18.6554 10.2126 19.2772C9.56844 19.8989 8.70815 20.2464 7.81289 20.2466C5.91956 20.2466 4.34622 18.6999 4.34622 16.7799C4.34622 14.4866 6.55956 12.7666 8.83956 13.4732V9.25988C4.23956 8.64655 0.212891 12.2199 0.212891 16.7799C0.212891 21.2199 3.89289 24.3799 7.79956 24.3799C11.9862 24.3799 15.3862 20.9799 15.3862 16.7799V8.39321C17.0569 9.59301 19.0627 10.2367 21.1196 10.2332V6.11321C21.1196 6.11321 18.6129 6.23321 16.7996 4.13988Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_1_335">
                    <rect
                      width="22"
                      height="24"
                      fill="white"
                      transform="translate(0 0.379883)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.list}>
              <p>{textData.company}</p>
              <div className={styles.underlineAdder}>
                <a href="/about-us">{textData.about}</a>
                <div className={styles.underline} />
              </div>
              <div className={styles.underlineAdder}>
                <a href="/menu">{textData.menu}</a>
                <div className={styles.underline} />
              </div>
              <div className={styles.underlineAdder}>
                <a href="/career">{textData.career}</a>
                <div className={styles.underline} />
              </div>
            </div>
            <div className={styles.list}>
              <p>{textData.help}</p>
              <div className={styles.underlineAdder}>
                <a href="/terms-conditions">
                  {currentLanguage === "en"
                    ? "Terms & Conditions"
                    : "Geschäftsbedingungen"}
                </a>
                <div className={styles.underline} />
              </div>
              <div className={styles.underlineAdder}>
                <a href="/privacy-policy">
                  {currentLanguage === "en"
                    ? "Privacy Policy"
                    : "Datenschutzerklärung"}
                </a>
                <div className={styles.underline} />
              </div>
              <div className={styles.underlineAdder}>
                <a href="/contact-us">{textData.contact}</a>
                <div className={styles.underline} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className={styles.rightsReserved}>
        © {new Date().getFullYear()} {textData.rights}
      </p>
    </>
  );
};

export default Footer;

const FooterLogo = () => {
  return (
    <svg
      onClick={() => window.open("/", "_self")}
      style={{ cursor: "pointer" }}
      className={styles.footerLogo}
      width="180"
      height="62"
      viewBox="0 0 180 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.0368 27.04C85.8936 27.3061 86.0368 28.18 87.0891 27.4434C88.5683 26.4087 91.199 23.7312 90.7459 20.1503C89.8807 13.3425 80.2579 12.5416 77.8201 17.4874C77.6739 17.7797 77.2414 17.7418 77.1683 17.4232C76.9461 16.4673 75.8675 15.9265 74.7041 18.756C73.3654 22.0036 74.9408 25.4762 76.42 27.1541C76.7123 27.4873 76.6275 27.9988 76.2446 28.221C74.1048 29.4662 68.6036 33.2341 70.6936 40.2844C72.6784 46.9901 82.0644 49.2583 87.0483 45.9114C87.0979 45.8793 87.1622 45.8997 87.1856 45.9553C87.4984 46.7123 88.4923 47.6535 91.2048 46.4727C95.9432 44.4119 98.609 35.1135 90.8833 26.5811C90.8102 26.4993 90.6757 26.5255 90.6349 26.6308C90.0502 28.1421 88.5185 29.3843 83.4499 30.425C74.6924 32.2255 76.5574 41.7869 83.2248 42.1494C89.6176 42.5002 91.1376 35.3182 91.4095 31.8807C91.4153 31.793 91.5469 31.7959 91.5556 31.8836C92.9119 48.7848 74.2803 46.0956 74.8502 35.8619C75.1163 31.0914 79.419 29.2148 80.4801 28.7763C80.7315 28.6712 81.1875 28.332 80.6905 27.8819C80.2258 27.4113 78.9367 26.2829 78.9338 23.1026C78.9309 20.2527 81.167 18.0662 83.4324 18.0808C86.5455 18.1072 89.1499 21.3079 86.0368 27.04Z"
        fill="#87E873"
      />
      <path
        d="M19.8316 28.9344C19.8316 27.9698 19.0336 27.1835 18.0544 27.1835C17.5049 27.1835 17.002 27.4261 16.6718 27.85L16.5812 27.9668L16.4496 27.8967C15.558 27.4144 14.5467 27.1572 13.5177 27.1572C10.0364 27.1572 7.20392 29.9897 7.20392 33.4711C7.20392 36.9261 10.0364 39.7585 13.5177 39.7849C14.3275 39.7849 15.1314 39.6271 15.9089 39.3172L16.283 39.1681L16.1369 39.5423C15.8475 40.2905 15.2921 40.8606 14.573 41.1471C13.8569 41.4305 13.053 41.4072 12.3748 41.0827C12.1352 40.9687 11.8808 40.9132 11.6236 40.9132C11.4219 40.9132 11.2173 40.9482 11.0215 41.0213C10.5772 41.1821 10.2235 41.5065 10.0247 41.9392C9.633 42.8132 10.0159 43.8596 10.8782 44.266C11.7113 44.6577 12.6086 44.8563 13.5412 44.8563C17.0079 44.8563 19.8286 42.0474 19.8286 38.5952V28.9344H19.8316ZM13.5177 36.0521C12.0796 36.0521 10.9104 34.8945 10.9104 33.4711C10.9104 32.0329 12.0796 30.8636 13.5177 30.8636C14.9413 30.8636 16.0988 32.0329 16.0988 33.4711C16.1017 34.8945 14.9413 36.0521 13.5177 36.0521Z"
        fill="#87E873"
      />
      <path
        d="M25.1601 32.5815C25.1601 31.6462 25.9463 30.8891 26.9518 30.8598C28.0246 30.8364 28.8664 30.0794 28.9103 29.1001C28.9337 28.5945 28.7525 28.0975 28.4133 27.735C28.0889 27.3873 27.6504 27.1914 27.1798 27.1797C26.7998 27.1797 25.6277 27.1797 24.5783 27.7175L24.4731 27.7702L24.3824 27.6942C24.0463 27.4077 23.6488 27.2557 23.2308 27.2557C22.2808 27.2557 21.5062 28.0303 21.5062 28.9803V37.7495C21.5062 38.7727 22.31 39.5764 23.3331 39.5764C24.3591 39.5764 25.1601 38.7727 25.1601 37.7495V32.5815Z"
        fill="#87E873"
      />
      <path
        d="M41.176 32.6759C40.7844 29.5307 38.101 27.1572 34.9382 27.1572C32.3805 27.1572 30.1064 28.6654 29.1447 31.001C28.4578 32.6555 28.484 34.6198 29.2178 36.1281C30.3022 38.3818 32.6232 39.7791 35.2685 39.7791C36.5254 39.7791 37.7473 39.4545 38.8054 38.8407C39.5363 38.4081 39.7818 37.4464 39.3491 36.6981C39.1357 36.3385 38.7763 36.0726 38.3669 35.9732C37.9753 35.8767 37.5719 35.941 37.2357 36.1515C35.6426 37.0225 33.3919 36.9173 32.1554 35.0348L31.9771 34.7631H39.0422C39.8051 34.7631 40.4073 34.5467 40.7756 34.1374C41.1029 33.7809 41.2374 33.2898 41.176 32.6759ZM32.1087 32.2258L32.1584 32.0094C32.3864 31.042 33.5059 29.9984 34.9148 29.9984C36.3413 29.9984 37.4374 31.0214 37.6713 32.0094L37.721 32.2258H32.1087Z"
        fill="#87E873"
      />
      <path
        d="M54.7421 32.6759C54.3504 29.5307 51.6669 27.1572 48.5042 27.1572C45.9465 27.1572 43.6724 28.6654 42.7106 31.001C42.0237 32.6555 42.0501 34.6198 42.7837 36.1281C43.8682 38.3818 46.1891 39.7791 48.8345 39.7791C50.0914 39.7791 51.3133 39.4545 52.3714 38.8407C53.1022 38.4081 53.3477 37.4464 52.9152 36.6981C52.7018 36.3385 52.3422 36.0726 51.933 35.9732C51.5413 35.8767 51.1379 35.941 50.8018 36.1515C49.2087 37.0225 46.9579 36.9173 45.7214 35.0348L45.5432 34.7631H52.6082C53.3712 34.7631 53.9733 34.5467 54.3415 34.1374C54.6719 33.7809 54.8035 33.2898 54.7421 32.6759ZM45.6746 32.2258L45.7244 32.0094C45.9524 31.042 47.0719 29.9984 48.4808 29.9984C49.9073 29.9984 51.0034 31.0214 51.2373 32.0094L51.2869 32.2258H45.6746Z"
        fill="#87E873"
      />
      <path
        d="M66.4897 32.5317C66.4897 29.5531 64.0666 27.1328 61.0908 27.1328C60.2636 27.1328 59.4394 27.32 58.7085 27.6736L58.6121 27.7204L58.5274 27.6531C58.197 27.3989 57.8024 27.2585 57.4165 27.2585C56.449 27.2585 55.6919 28.0273 55.6919 29.0095V37.7524C55.6919 38.7608 56.5104 39.5793 57.5188 39.5793C58.5274 39.5793 59.3457 38.7608 59.3457 37.7524V32.5317C59.3457 31.5818 60.1292 30.8101 61.0908 30.8101C62.0555 30.8101 62.8125 31.5672 62.8125 32.5317V37.7524C62.8125 38.7433 63.6602 39.5793 64.6657 39.5793C65.6743 39.5793 66.4927 38.7608 66.4927 37.7524V32.5317H66.4897Z"
        fill="#87E873"
      />
      <path
        d="M105.958 27.0576C104.896 27.0576 103.83 27.3295 102.874 27.8469L102.754 27.9111L102.66 27.8118C102.321 27.4493 101.877 27.2594 101.368 27.2594C100.433 27.2594 99.6437 28.0485 99.6437 28.984V41.8075C99.6437 42.7429 100.383 43.5117 101.327 43.5584H101.497C102.505 43.5584 103.324 42.74 103.324 41.7315V39.1154L103.564 39.2118C104.341 39.5216 105.148 39.6795 105.958 39.6795C109.439 39.6795 112.271 36.847 112.271 33.3656C112.271 29.8842 109.436 27.0576 105.958 27.0576ZM105.958 36.0023C104.481 36.0023 103.324 34.8448 103.324 33.3685C103.324 31.8924 104.481 30.7349 105.958 30.7349C107.434 30.7349 108.591 31.8924 108.591 33.3685C108.591 34.8448 107.434 36.0023 105.958 36.0023Z"
        fill="#87E873"
      />
      <path
        d="M116.965 32.5815C116.965 31.6462 117.751 30.8891 118.757 30.8598C119.83 30.8364 120.672 30.0794 120.715 29.1001C120.739 28.5945 120.557 28.0975 120.218 27.735C119.894 27.3873 119.456 27.1914 118.985 27.1797C118.605 27.1797 117.433 27.1797 116.383 27.7175L116.278 27.7702L116.188 27.6942C115.851 27.4077 115.454 27.2557 115.033 27.2557C114.083 27.2557 113.308 28.0303 113.308 28.9803V37.7495C113.308 38.7727 114.112 39.5764 115.135 39.5764C116.158 39.5764 116.962 38.7727 116.962 37.7495V32.5815H116.965Z"
        fill="#87E873"
      />
      <path
        d="M126.962 27.1572C123.48 27.1572 120.648 29.9897 120.648 33.4711C120.648 36.9523 123.48 39.7849 126.962 39.7849C130.443 39.7849 133.276 36.9523 133.276 33.4711C133.276 29.9897 130.443 27.1572 126.962 27.1572ZM126.962 36.1047C125.485 36.1047 124.329 34.9472 124.329 33.4711C124.329 31.9948 125.485 30.8373 126.962 30.8373C128.438 30.8373 129.595 31.9948 129.595 33.4711C129.595 34.9472 128.438 36.1047 126.962 36.1047Z"
        fill="#87E873"
      />
      <path
        d="M139.102 36.3317H138.721C137.865 36.3317 137.482 35.6214 137.482 34.9141V30.8861H139.508C140.391 30.8861 141.107 30.1815 141.107 29.3134C141.107 28.416 140.406 27.7145 139.508 27.7145H137.482V25.5368C137.482 24.5167 136.652 23.6836 135.629 23.6836C134.607 23.6836 133.776 24.5138 133.776 25.5368V34.9141C133.776 37.8342 135.902 40.0353 138.719 40.0353H139.098C140.119 40.0353 140.952 39.2051 140.952 38.1821C140.955 37.1619 140.122 36.3317 139.102 36.3317Z"
        fill="#87E873"
      />
      <path
        d="M154.482 32.6759C154.09 29.5307 151.408 27.1572 148.245 27.1572C145.687 27.1572 143.413 28.6654 142.451 31.001C141.764 32.6555 141.791 34.6198 142.524 36.1281C143.609 38.3818 145.93 39.7791 148.575 39.7791C149.832 39.7791 151.054 39.4545 152.112 38.8407C152.843 38.4081 153.085 37.4464 152.656 36.6981C152.443 36.3385 152.083 36.0726 151.673 35.9732C151.282 35.8796 150.878 35.941 150.542 36.1515C148.949 37.0225 146.698 36.9173 145.462 35.0348L145.284 34.7631H152.349C153.112 34.7631 153.714 34.5467 154.082 34.1374C154.409 33.7809 154.544 33.2898 154.482 32.6759ZM145.416 32.2258L145.468 32.0094C145.695 31.042 146.815 29.9984 148.225 29.9984C149.651 29.9984 150.747 31.0214 150.978 32.0094L151.027 32.2258H145.416Z"
        fill="#87E873"
      />
      <path
        d="M159.485 28.9607C159.485 27.9405 158.654 27.1074 157.632 27.1074C156.611 27.1074 155.778 27.9376 155.778 28.9607V37.7299C155.778 38.7501 156.608 39.5832 157.632 39.5832C158.652 39.5832 159.485 38.7531 159.485 37.7299V28.9607Z"
        fill="#87E873"
      />
      <path
        d="M157.63 26.2009C158.484 26.2009 159.181 25.4936 159.181 24.6284C159.181 23.7895 158.469 23.0791 157.63 23.0791C156.763 23.0791 156.058 23.7749 156.058 24.6284C156.058 25.4936 156.763 26.2009 157.63 26.2009Z"
        fill="#87E873"
      />
      <path
        d="M172.186 32.5317C172.186 29.5531 169.762 27.1328 166.787 27.1328C165.96 27.1328 165.135 27.32 164.405 27.6736L164.307 27.7204L164.223 27.6531C163.893 27.3989 163.499 27.2585 163.112 27.2585C162.145 27.2585 161.388 28.0273 161.388 29.0095V37.7524C161.388 38.7608 162.206 39.5793 163.214 39.5793C164.223 39.5793 165.042 38.7608 165.042 37.7524V32.5317C165.042 31.5818 165.825 30.8101 166.787 30.8101C167.751 30.8101 168.509 31.5672 168.509 32.5317V37.7524C168.509 38.7433 169.356 39.5793 170.362 39.5793C171.37 39.5793 172.188 38.7608 172.188 37.7524V32.5317H172.186Z"
        fill="#87E873"
      />
    </svg>
  );
};
