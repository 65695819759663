import React, { useEffect, useState } from "react";
import styles from "./Recipes.module.scss";
import recipesImg from "../../assets/recipesImg.jpg";
import RecipePreview from "../../Components/RecipePreview/RecipePreview";
import { getRecipes, getRecipespage } from "../../utils/api";
import { useLanguage } from "../../context/Language";
import Error from "../../Components/Error/Error";

const Recipes = () => {
  const { currentLanguage } = useLanguage();
  const [error, setError] = useState(false);
  const [recipes, setRecipes] = useState([]);
  const [textData, setTextData] = useState({});

  useEffect(() => {
    //fetch static stuff
    const fetchData = async () => {
      try {
        const recipesres = await getRecipes(currentLanguage);
        const textres = await getRecipespage(currentLanguage);
        setRecipes(recipesres.data);
        setTextData(textres.data)
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage]);

  if (error) {
    return <Error />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.hero}>
        <h2>{textData.title}</h2>
        <img src={recipesImg} alt="" />
      </div>
      <h3>{textData.newestRecipes}</h3>
      <div className={styles.recipesWrapper}>
        {recipes.map((data, index) => (
          <RecipePreview data={data} key={index} width="484px" />
        ))}
      </div>
      <h5>{textData.shoutout}</h5>
    </div>
  );
};

export default Recipes;
